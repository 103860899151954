@import "../../../../public/styles/variables";
@import "../../../../public/styles/breakpoints";

.order {
  padding: 20px;
  background-color: white;
  position: relative;
  border: 1px solid $typo-one;
  box-shadow: $shadow;
  border-radius: 5px;

  &__top {
    margin-top: -10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid $typo-one;
    padding: 20px;
    border-radius: 5px;
    &__left,
    &__right {
      display: flex;
      align-items: center;
      flex-direction: column;
      row-gap: 10px;

      &__icon {
        font-size: 20px;
        color: $typo-two;
      }

      & > p {
        letter-spacing: 1px;
        @include if-device(third, 768) {
          font-size: 12px;
        }
      }
    }
  }
  &__mid {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;

    &__left {
      display: flex;
      align-items: flex-start;
      column-gap: 10px;

      @include if-device(phone) {
        flex-wrap: wrap;
        row-gap: 10px;
        width: 70%;
      }

      &__img {
        height: 80px;
        width: 80px;
        border-radius: 5px;
        overflow: hidden;
        @include if-device(phone) {
          aspect-ratio: 1/1;
          width: 50%;
          height: auto;
        }
        & > img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      &__det {
        & > h3 {
          font-family: $english-regular;
          color: $third-color;
          margin-bottom: 5px;

          @include if-device(phone) {
            font-size: 16px;
          }
        }

        & > p {
          color: $typo-two;

          @include if-device(phone) {
            font-size: 14px;
          }
        }
      }
    }

    &__right {
      color: $black;

      @include if-device(phone) {
        text-align: end;
        width: 30%;
      }
    }
  }

  &__delivery {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: $black;
    margin: 20px 0;
  }

  &__custom {
    margin-bottom: 20px;
    &__p {
      color: $black;

      &__icon {
        margin-left: 5px;
        font-size: 18px;
        color: $typo-two;
      }
    }

    &__noData {
      font-family: $english-regular;
      font-size: 16px;
    }

    &__table {
      list-style-type: none;
      margin-top: 5px;
      width: 100%;
      border: 1px solid #000;
      border-collapse: collapse;
      & > tbody {
        & > tr {
          & > td,
          & > th {
            font-family: $english-regular;
            font-size: 16px;
            text-align: center;
            text-transform: capitalize;
            border: 1px solid #000;
            border-collapse: collapse;
            font-weight: 400;
            padding: 5px;
            & > span {
              font-family: $english-regular;
              font-size: 16px;
            }
          }
        }
      }
    }
  }

  &__btn {
    display: none;
    @include if-device(third, 768) {
      display: block;
      position: fixed;
      bottom: 0;
      left: 0;
      width: 100%;
      padding: 0 50px;
      background-color: $white;
    }
  }
}
