@import "../../../public/styles/variables";
@import "../../../public/styles/breakpoints";

.loginCont {
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.7);
  height: 100%;
  width: 100%;
  z-index: 998;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.8s ease-in-out;

  &__login {
    width: 30%;
    background-color: $white;
    border-top: 10px solid $third-color;
    height: 50%;
    padding: 50px;
    z-index: 999;
    position: relative;
    // @include if-device(phone) {
    //   width: 95%;
    //   padding: 30px;
    // }
    // @include if-device(tablet) {
    //   width: 75%;
    // }
    // @include if-device(laptop) {
    //   width: 60%;
    // }
    // @include if-device(desktop) {
    //   width: 50%;
    // }

    &__imgCont {
      width: 100%;
      position: relative;
      height: 40px;
      &__img {
        width: 100px;
        height: 100px;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        background-color: $white;
        border-radius: 50%;
        top: -100px;
        display: flex;
        align-items: center;
        justify-content: center;

        & > img {
          width: 80%;
          height: 80%;
        }
      }
    }
  }
}
