@import "../../../public/styles/variables";
@import "../../../public/styles/breakpoints";

.checkout {
  padding: 50px 0 50px;
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  gap: 20px;

  @include if-device(second, 1024) {
    flex-wrap: wrap;
  }

  &__right {
    position: sticky;
    top: 100px;
    width: 45%;

    @include if-device(second, 1024) {
      width: 100%;
    }

    &__whatsapp {
      padding: 20px;
      background-color: white;
      position: relative;
      border: 1px solid $typo-one;
      box-shadow: $shadow;
      border-radius: 5px;
      margin-bottom: 20px;
      display: flex;
      align-items: center;
      column-gap: 20px;
      justify-content: center;
      cursor: pointer;

      &__img {
        height: 50px;
        width: 50px;
        border-radius: 5px;
        overflow: hidden;

        & > img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      &__paras {
        color: $typo-two;
        text-transform: capitalize;
        font-size: 18px;

        @include if-device(desktop) {
          flex: 1;
        }
        @include if-device(second, 1024) {
          flex: 1;
          font-size: 14px;
        }
      }
    }
  }

  &__left {
    border-radius: 10px;
    width: 55%;
    display: flex;
    flex-direction: column;
    row-gap: 20px;

    @include if-device(second, 1024) {
      width: 100%;
    }
  }
}
