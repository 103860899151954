@import "../../../public/styles/variables";
@import "../../../public/styles/breakpoints";

.button {
  position: relative;
  // padding: 8px 25px;
  // background: $first-color;
  font-size: 20px;
  color: $first-color;
  text-align: end;
  // border: 3px solid $first-color;
  border-radius: 8px;
  box-shadow: 0 0 0 rgba(100, 100, 111, 0.2);
  transition: all 0.3s ease-in-out;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }

  @include if-device(phone) {
    font-size: 16px;
    padding: 5px 0;
    font-weight: 400;
    width: 30%;
    // margin-right: 15px;
  }

  &__star1 {
    position: absolute;
    top: 20%;
    left: 20%;
    width: 25px;
    height: auto;
    filter: drop-shadow(0 0 0 #fffdef);
    z-index: -5;
    transition: all 1s cubic-bezier(0.05, 0.83, 0.43, 0.96);
  }
  &__star2 {
    position: absolute;
    top: 45%;
    left: 40%;
    width: 15px;
    height: auto;
    filter: drop-shadow(0 0 0 #fffdef);
    z-index: -5;
    transition: all 1s cubic-bezier(0, 0.4, 0, 1.01);
  }

  &__star3 {
    position: absolute;
    top: 40%;
    left: 40%;
    width: 5px;
    height: auto;
    filter: drop-shadow(0 0 0 #fffdef);
    z-index: -5;
    transition: all 1s cubic-bezier(0, 0.4, 0, 1.01);
  }

  &__star4 {
    position: absolute;
    top: 20%;
    left: 40%;
    width: 8px;
    height: auto;
    filter: drop-shadow(0 0 0 #fffdef);
    z-index: -5;
    transition: all 0.8s cubic-bezier(0, 0.4, 0, 1.01);
  }

  &__star5 {
    position: absolute;
    top: 25%;
    left: 42%;
    width: 15px;
    height: auto;
    filter: drop-shadow(0 0 0 #fffdef);
    z-index: -5;
    transition: all 0.6s cubic-bezier(0, 0.4, 0, 1.01);
  }

  &__star6 {
    position: absolute;
    top: 5%;
    left: 40%;
    width: 5px;
    height: auto;
    filter: drop-shadow(0 0 0 #fffdef);
    z-index: -5;
    transition: all 0.8s ease;
  }

  &:hover &__star1 {
    position: absolute;
    top: -80%;
    left: -30%;
    width: 25px;
    height: auto;
    filter: drop-shadow(0 0 10px #fffdef);
    z-index: 2;
  }

  &:hover &__star2 {
    position: absolute;
    top: -25%;
    left: 10%;
    width: 15px;
    height: auto;
    filter: drop-shadow(0 0 10px #fffdef);
    z-index: 2;
  }

  &:hover &__star3 {
    position: absolute;
    top: 55%;
    left: 25%;
    width: 5px;
    height: auto;
    filter: drop-shadow(0 0 10px #fffdef);
    z-index: 2;
  }

  &:hover &__star4 {
    position: absolute;
    top: 30%;
    left: 80%;
    width: 8px;
    height: auto;
    filter: drop-shadow(0 0 10px #fffdef);
    z-index: 2;
  }

  &:hover &__star5 {
    position: absolute;
    top: 25%;
    left: 100%;
    width: 15px;
    height: auto;
    filter: drop-shadow(0 0 10px #fffdef);
    z-index: 2;
  }

  &:hover &__star6 {
    position: absolute;
    top: 5%;
    left: 60%;
    width: 5px;
    height: auto;
    filter: drop-shadow(0 0 10px #fffdef);
    z-index: 2;
  }

  // &:hover {
  //   background: transparent;
  //   color: $first-color;
  //   box-shadow: 0 0 25px rgba(100, 100, 111, 0.2);
  // }
}

.fil0 {
  fill: $third-color;
}
