@import "../../../../public/styles/variables";
@import "../../../../public/styles/breakpoints";

.form {
  p {
    margin-top: 0;
    margin-bottom: 0.5rem;
    font-weight: 500;
    line-height: 1.2;
    font-size: 14px;
    font-family: $english-regular;
    z-index: 1;
    color: #212529;

    @include if-device(third, 768) {
      font-size: 12px;
    }
  }
  h2 {
    font-size: 1.25rem;
    font-weight: 500;
    line-height: 1.2;
    color: #212529;
    margin: 20px 0 15px;
    font-family: $english;
  }
  &__top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 10px;

    @include if-device(third, 768) {
      flex-wrap: wrap;
      row-gap: 10px;
    }

    &__left {
      &__field {
        display: flex;
        & > input {
          display: block;
          width: 65%;
          padding: 0.375rem 0.75rem;
          font-size: 1rem;
          height: 40px;
          font-weight: 400;
          color: #212529;
          appearance: none;
          background-color: #fff;
          background-clip: padding-box;
          border: 1px solid #dee2e6;
          border-radius: 0.375rem 0 0 0.375rem;
          transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
          font-family: $english-regular;

          &::-webkit-outer-spin-button,
          &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
          }

          &:focus {
            outline: none;
            border: 1px solid #aaa;
          }
        }

        & > select {
          display: inline-block;
          width: 35%;
          padding: 0.375rem 0.75rem 0.375rem 0.75rem;
          font-size: 16px;
          color: #495057;
          vertical-align: middle;
          background-size: 8px 10px;
          border: 1px solid #ced4da;
          border-radius: 0 0.25rem 0.25rem 0;
          font-family: $english-regular;
          //   appearance: none;

          &:focus {
            outline: none;
            border: 1px solid #aaa;
          }
        }
      }
    }

    &__right {
      &__field {
        display: flex;
        & > input {
          display: block;
          width: 65%;
          height: 40px;
          padding: 0.375rem 0.75rem;
          font-size: 1rem;
          font-weight: 400;
          color: #212529;
          appearance: none;
          background-color: #fff;
          background-clip: padding-box;
          border: 1px solid #dee2e6;
          border-radius: 0.375rem 0 0 0.375rem;
          transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
          font-family: $english-regular;

          &::-webkit-outer-spin-button,
          &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
          }

          &:focus {
            outline: none;
            border: 1px solid #aaa;
          }
        }

        & > select {
          display: inline-block;
          width: 35%;
          height: 40px;
          padding: 0.375rem 0.75rem 0.375rem 0.75rem;
          font-size: 16px;
          color: #495057;
          vertical-align: middle;
          background-size: 8px 10px;
          font-family: $english-regular;
          border: 1px solid #ced4da;
          border-radius: 0 0.25rem 0.25rem 0;
          //   appearance: none;

          &:focus {
            outline: none;
            border: 1px solid #aaa;
          }
        }
      }
    }
  }

  &__mid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    row-gap: 15px;

    @include if-device(third, 768) {
      grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    }

    @include if-device(laptop) {
      grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    }

    &__loader {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 180px;
      margin-bottom: 50px;
    }

    &__field {
      display: flex;
      align-items: center;
      column-gap: 10px;

      & > input {
        height: 20px;
        width: 20px;
        accent-color: #008000;
      }

      & > p {
        font-size: 16px;
        font-family: $english-regular;
        margin: 0;
        @include if-device(third, 768) {
          width: 100%;
          font-size: 14px;
        }

        @include if-device(laptop) {
          width: 100%;
          font-size: 14px;
        }
      }
    }
  }

  &__btn {
    width: 180px;
    margin-top: 30px;
  }

  &__bottom {
    input {
      display: block;
      width: 100%;
      padding: 0.375rem 0.75rem;
      font-size: 1rem;
      height: 40px;
      font-weight: 400;
      color: #212529;
      appearance: none;
      font-family: $english-regular;
      background-color: #fff;
      background-clip: padding-box;
      border: 1px solid #dee2e6;
      border-radius: 0.375rem;
      transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      &:focus {
        outline: none;
        border: 1px solid #aaa;
      }
    }

    &__top {
      display: grid;
      align-items: center;
      gap: 20px;
      grid-template-columns: repeat(3, 1fr);
      @include if-device(third, 768) {
        grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
      }
      & > div {
        width: 100%;
      }
    }
  }
}
