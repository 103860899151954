@import "../../../public/styles/variables";
@import "../../../public/styles/breakpoints";
.categories {
  padding: 10px 0;
  margin: 5px 0 10px 0;
  position: relative;

  &__body {
    width: 100%;
    height: 120px;
  }
  &__body1 {
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 50px;
    width: 100%;
  }
}
