.offerCard {
  box-shadow: rgb(0 0 0 / 16%) 0px 1px 4px;
  padding: 8px;
  margin: 8px;
  cursor: pointer;
  height: 300px;
  & > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
