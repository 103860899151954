@import "../../../../public/styles/variables";

.details {
  border: 1px solid $typo-one;
  padding: 20px;
  box-shadow: $shadow;
  border-radius: 5px;
  &__content {
    margin-top: 20px;

    & > p {
      color: $typo-one;
      font-size: 16px !important;
      font-family: $english-regular !important;
      text-transform: capitalize;
      font-size: 16px !important;
      // display: flex;

      // column-gap: 10px;
      & > span {
        font-size: 16px !important;
        font-family: $english-regular !important;
        & > span {
          & > span {
            font-size: 16px !important;
            font-family: $english-regular !important;
          }
        }
      }

      &:nth-child(4) {
        margin-bottom: 10px !important;
      }

      &:nth-child(5) {
        display: none;
      }
      &:nth-child(6) {
        margin-bottom: 0 !important;
        & > span {
          font-size: 16px !important;
        }
      }
    }
    & > ul {
      list-style-type: none;
      display: flex;
      flex-direction: column;
      row-gap: 10px;

      & > li {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        column-gap: 10px;
        font-family: $english-regular;
        font-size: 16px;
        color: $typo-two;

        &::before {
          content: "\f111";
          font-family: FontAwesome;
          color: $first-color;
          font-size: 12px;
          margin-top: 4px;
        }
      }
    }
  }
}
