@import "../../../public/styles/variables";
@import "../../../public/styles/breakpoints";
.marriage {
  overflow: hidden;
  display: flex;
  align-items: flex-start;
  //   column-gap: 20px;
  height: 95vh;
  @include if-device(third, 768) {
    flex-wrap: wrap;
    height: 100%;
  }
  &__close {
    position: absolute;
    top: 10px;
    background-color: $third-color;
    height: 30px;
    width: 30px;
    display: grid;
    place-items: center;
    border-radius: 50%;
    cursor: pointer;
    font-weight: 600;
    transition: 0.3s ease;
    color: $white;
    z-index: 1;
    &:hover {
      background-color: red;
    }
    @include if-device(third, 768) {
      right: 10px;
      top: 10px;
      z-index: 2;
    }
  }
  &__left {
    width: 30%;
    position: relative;
    background-color: #f1e2db;
    padding: 20px 15px 20px 20px;
    height: 100%;

    @include if-device(third, 768) {
      width: 100%;
      height: 30%;
    }

    & > h2 {
      font-size: 2rem;
      margin-top: 0;
      margin-bottom: 0.5rem;
      font-weight: 500;
      line-height: 1.2;
      color: #212529;
    }

    & > p {
      margin-top: 0;
      margin-bottom: 0.5rem;
      font-weight: 500;
      line-height: 1.5;
      font-family: $english-regular;
      font-size: 1rem;
      padding: 0 20px 0 0;
      text-transform: capitalize;

      @include if-device(third, 768) {
        max-width: 200px;
      }
    }

    &__img {
      width: 100%;
      position: absolute;
      bottom: -5px;
      left: 20px;

      & > img {
        width: 100%;
        height: 100%;
        @include if-device(third, 768) {
          width: 50%;
        }
      }

      @include if-device(third, 768) {
        bottom: -3px;
        left: 70%;
        width: 70%;
      }
    }
  }
  &__right {
    width: 70%;
    height: 100%;
    padding: 20px 20px 20px 15px;
    background-color: #f8f9fa;

    @include if-device(third, 768) {
      width: 100%;
    }

    & > h2 {
      font-size: 1.25rem;
      font-weight: 500;
      line-height: 1.2;
      color: #212529;
      margin: 0 0 15px;
      font-family: $english;
    }
  }
}
