@import "../../../public/styles/variables";

.bookings {
  &__tab {
    margin: 20px 0;
    // padding: 0 20px;
    & > ul {
      display: flex;
      align-items: center;
      justify-content: space-between;
      list-style-type: none;
      border-radius: 10px;
      overflow: hidden;

      & > li {
        display: flex;
        align-items: center;
        justify-content: center;

        height: 50px;
        width: 50%;
        color: $white;
        background-color: gainsboro;
        transition: all 0.2s ease-in;
        transform-origin: left;
        cursor: pointer;
      }
    }
  }
}

.active {
  background-color: $first-color !important;
}
