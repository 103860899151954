@import "../../../public/styles/variables";
@import "../../../public/styles/breakpoints";

.bookingCard {
  box-shadow: $shadow;
  padding: 20px;
  display: flex;
  align-items: center;

  @include if-device(first, 1024) {
    flex-wrap: wrap;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(1, 1fr));
    padding: 5px;
  }

  column-gap: 20px;
  &__left {
    width: 50%;
    height: 280px;
    @include if-device(first, 1024) {
      aspect-ratio: 1/1;
      height: auto;
      width: 100%;
    }

    & > img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__right {
    width: 50%;
    @include if-device(first, 1024) {
      width: 100%;
    }

    & > p {
      font-size: 1rem;
      font-family: $english-regular;
      margin-bottom: 20px;
      @include if-device(first, 1024) {
        font-family: $english-regular;
        font-weight: 500;
        font-size: 0.8rem;
        margin-bottom: 5px;
      }
    }

    & > h2,
    & > h1 {
      font-size: 1.6rem;
      font-family: $english;
      font-weight: 600;
      text-transform: capitalize;
      @include if-device(first, 1024) {
        font-size: 1rem;
        font-family: $english-regular;
      }
    }

    &__childs {
      display: flex;
      align-items: center;
      column-gap: 10px;
      margin: 10px 0;
      @include if-device(first, 1024) {
        margin: 5px 0;
      }

      &__rating {
        height: 35px;
        width: 60px;
        background-color: $first-color;
        margin: 10px 0;
        display: flex;
        align-items: center;
        justify-content: center;
        column-gap: 5px;
        @include if-device(first, 1024) {
          margin: 0;
          font-family: $english-regular;
          width: 50px;
          height: 30px;
        }

        & > p {
          font-family: $english-regular;
          font-size: 1rem;
          font-weight: 600;
          color: $white;
          @include if-device(first, 1024) {
            font-family: $english-regular;
            font-weight: 500;
          }
        }

        &__icon {
          color: $white;
          font-size: 20px;
          margin-top: -4px;
          @include if-device(first, 1024) {
            font-size: 16px;
          }
        }
      }

      &__review {
        font-family: $english-regular;
        font-size: 1rem;
        @include if-device(first, 1024) {
          font-family: $english-regular;
          font-weight: 500;
          font-size: 0.8rem;
        }
      }
    }

    &__childs1 {
      display: flex;
      align-items: center;
      column-gap: 10px;
      margin: 10px 0;
      @include if-device(first, 1024) {
        margin: 5px 0;
        flex-wrap: wrap;
        justify-content: center;
      }

      &__timedate {
        width: 60%;
        font-size: 1rem;
        font-family: $english-regular;
        @include if-device(first, 1024) {
          font-family: $english-regular;
          font-weight: 500;
          font-size: 0.8rem;
          width: 100%;
          margin-bottom: 5px;
        }
      }

      &__btn {
        width: 40%;
        height: 40px;
        @include if-device(first, 1024) {
          width: 100%;
        }
      }
    }
  }
}
