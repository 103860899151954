@import "../../../public/styles/variables";
@import "../../../public/styles/breakpoints";

.banner {
  position: relative;
  z-index: 0;

  &__bgImg {
    width: 100%;
    height: auto;
    @include if-device(phone) {
      height: 180px;
    }

    & > img {
      height: 100%;
      width: 100%;
      object-fit: cover;
      @include if-device(phone) {
        object-fit: fill !important;
      }
    }
  }
}
