@import "../../../public/styles/variables";
@import "../../../public/styles/breakpoints";

.tourForm {
  height: 80vh;
  background-color: #f8f9fa;
  // border-radius: 10px;
  overflow: hidden;
  display: flex;
  position: relative;

  @include if-device(second, 1024) {
    flex-wrap: wrap;
    height: 100%;
  }

  &__close {
    position: absolute;
    top: 10px;
    background-color: $third-color;
    height: 30px;
    width: 30px;
    display: grid;
    place-items: center;
    border-radius: 50%;
    cursor: pointer;
    font-weight: 600;
    transition: 0.3s ease;
    color: $white;
    &:hover {
      background-color: red;
    }

    @include if-device(second, 1024) {
      right: 10px;
      top: 10px;
      z-index: 2;
    }
  }

  h2 {
    font-size: 1.25rem;
    font-weight: 500;
    line-height: 1.2;
    color: #212529;
    margin: 15px 0 15px;
    font-family: $english;
  }

  &__left {
    position: relative;
    background-color: #f1e2db;
    width: 30%;
    height: 100%;
    padding: 20px;
    @include if-device(second, 1024) {
      width: 100%;
      height: 30%;
    }

    & > h2 {
      font-size: 2rem;
      margin-top: 0;
      margin-bottom: 0.5rem;
      font-weight: 500;
      line-height: 1.2;
      color: #212529;
      z-index: 1;
      position: relative;
    }
    & > p {
      margin-top: 0;
      margin-bottom: 0.5rem;
      font-weight: 500;
      line-height: 1.5;
      font-family: $english-regular;
      font-size: 1rem;
      padding: 0 20px 0 0;
      text-transform: capitalize;
      z-index: 1;
      position: relative;

      @include if-device(second, 1024) {
        max-width: 200px;
      }
    }
    &__anim {
      position: absolute;
      bottom: 0;
      right: 0;
      width: 100%;

      overflow: hidden;

      @include if-device(third, 768) {
        bottom: -50px;
        right: 0;
        width: 50%;
        background-color: #f8f9fa;
        border-radius: 70% 23% 0 0;
      }

      @include if-device(laptop) {
        bottom: -80px;
        right: 0;
        width: 50%;
        background-color: #f8f9fa;
        border-radius: 70% 23% 0 0;
      }

      &__animation {
        width: 100%;
        height: 100%;
      }
    }
  }

  &__right {
    width: 70%;
    padding: 20px;
    @include if-device(second, 1024) {
      width: 100%;
    }
    &__top {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 20px;

      @include if-device(second, 1024) {
        grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
      }
    }
    & > p {
      margin-top: 10px;
      margin-bottom: 0.5rem;
      font-weight: 500;
      line-height: 1.2;
      font-size: 14px;
      font-family: $english-regular;
      z-index: 1;
      color: #212529;
    }
    &__textarea {
      display: block;
      width: 100%;
      padding: 0.375rem 0.75rem;
      font-size: 1.1rem;
      height: 40px;
      font-weight: 400;
      color: #212529;
      appearance: none;
      font-family: $english-regular;
      background-color: #fff;
      background-clip: padding-box;
      border: 1px solid #dee2e6;
      border-radius: 0.375rem;
      transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
      min-height: 132px;

      &::placeholder {
        opacity: 1;
        // color: #a3acb8;
        font-size: 1.1rem;
      }

      &:focus {
        outline: none;
      }
    }

    &__additional {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 20px;

      @include if-device(second, 1024) {
        grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
      }
    }
    &__btn {
      width: 180px;
      margin-top: 20px;
    }
  }
}
