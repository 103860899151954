@import "../../../../public/styles/variables";

.socialPlatforms {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 20px 0;
  & > h2 {
    text-transform: capitalize;
    font-family: $english-regular;
    color: $first-color;
    font-size: 20px;
    margin: 0;
    margin-bottom: 5px;
    flex: 1;
    text-align: right;
  }
}
