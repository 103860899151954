@import "../../../public/styles/variables";
@import "../../../public/styles/breakpoints";

.bestSellerZone {
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 10px;
  padding: 0 0 10px 0;

  @include if-device(laptop) {
    flex-wrap: wrap;
    gap: 10px;
  }

  @include if-device(tablet) {
    flex-wrap: wrap;
    gap: 10px;
  }

  @include if-device(phone) {
    flex-wrap: wrap;
    gap: 10px;
  }

  &__seller {
    width: 33%;
    height: 210px;
    position: relative;
    border-radius: 10px;
    overflow: hidden;

    @include if-device(laptop) {
      width: 48%;
    }
    @include if-device(tablet) {
      width: 48%;
    }
    @include if-device(phone) {
      width: 100%;
    }

    &__title {
      position: absolute;
      top: 10px;
      left: 50%;
      transform: translateX(-50%);
      font-size: 20px;
      color: $white;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      @include if-device(tablet) {
        font-size: 18px;
      }
      @include if-device(phone) {
        font-size: 18px;
      }
    }

    & > img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}
