@import "../../../../public/styles/variables";

.videos {
  border: 1px solid $typo-one;
  padding: 20px;
  box-shadow: $shadow;
  border-radius: 5px;

  &__content {
    width: 100% !important;
    height: 300px;
    border-radius: 5px;
    overflow: hidden;
  }
}
