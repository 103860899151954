@import "../../../../public/styles/variables";

.toFrom {
  margin: 30px 0 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &__left {
    & > h2 {
      text-transform: capitalize;
      font-family: $english-regular;
      color: $first-color;
      font-size: 20px;
      margin: 0;
      margin-bottom: 5px;
    }

    & > p {
      text-transform: capitalize;
      font-family: $english-regular;
      color: $black;
      font-size: 20px;
      margin: 0;
    }

    &__ul {
      list-style-type: none;
      line-height: 1.3;

      &__li {
        &__span {
          text-transform: none !important;
          color: $typo-one !important;
        }
        & > span {
          font-family: $english-regular;
          color: $black;
          text-transform: capitalize;
          font-size: 14px;
        }
      }
    }
  }

  &__right {
    text-align: end;
    & > h2 {
      text-transform: capitalize;
      font-family: $english-regular;
      color: $first-color;
      font-size: 25px;
      margin: 0;
      margin-bottom: 5px;
    }

    & > p {
      text-transform: capitalize;
      font-family: $english-regular;
      color: $black;
      font-size: 20px;
      margin: 0;
    }

    &__ul {
      list-style-type: none;
      line-height: 1.3;

      &__li {
        &__span {
          text-transform: none !important;
          color: $typo-one !important;
        }
        & > span {
          font-family: $english-regular;
          color: $black;
          text-transform: capitalize;
          font-size: 14px;
        }
      }
    }
  }
}
