@import "../../../../public/styles/variables";

.faqs {
  border: 1px solid $typo-one;
  padding: 20px;
  box-shadow: $shadow;
  border-radius: 5px;

  &__content {
    margin: 20px 0 10px;
    &__que {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      column-gap: 10px;
      margin-bottom: 5px;

      &__title {
        width: 20px;
      }
      & > h3 {
        font-family: $english-regular;
        font-size: 18px;
      }
    }
    &__ans {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      column-gap: 10px;

      &__title {
        width: 20px;
      }

      &__ans {
        flex: 1;
        font-family: $english-regular;
      }
    }
  }
}
