@import "../../../public/styles/variables";
@import "../../../public/styles/breakpoints";

.offerBanner {
  margin: 50px 0 50px;
  // padding: 10px;
  // border: 1px solid #000;
  // box-shadow: 0 0 1px rgba(0, 0, 0, 0.13), 0 1px 3px rgba(0, 0, 0, 0.2);

  @include if-device(phone) {
    margin: 0;
  }

  &__top {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    &__title {
      margin-bottom: 10px;
      color: $second-color;
      font-size: 25px;
      display: inline-block;
      padding: 5px 10px;
      font-weight: 500;

      @include if-device(phone) {
        font-size: $font-size;
      }
    }
  }

  &__bottom {
    height: auto;
    width: 100%;
    & > img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }
}
