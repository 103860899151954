@import "../../../public/styles/variables";
@import "../../../public/styles/breakpoints";

.blogDetails {
  box-shadow: $shadow;
  margin: 50px 0;
  padding: 20px;

  & > h2 {
    font-family: $english;
    font-size: 1.8rem;
    font-weight: 500;
    margin-bottom: 20px;
    @include if-device(first, 1024) {
      font-size: 1.2rem;
    }
  }

  &__img {
    width: 100%;
    height: 400px;
    @include if-device(first, 1024) {
      height: auto;
    }

    & > img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__bottom {
    display: flex;
    flex-direction: column;
    row-gap: 50px;
    & > div {
      & > h2 {
        font-family: $english;
        font-size: 1.8rem;
        font-weight: 500;
        margin-bottom: 20px;
        @include if-device(first, 1024) {
          font-size: 1.2rem;
        }
      }

      & > p {
        font-family: $english-regular;
        font-size: 14px;
        text-transform: capitalize;
        font-weight: 400 !important;
        color: $typo-one !important;
        line-height: 1.5;
      }
    }
  }

  &__date {
    margin: 10px 0;
    display: flex;
    align-items: center;
    column-gap: 20px;
    &__child {
      display: flex;
      align-items: center;
      column-gap: 5px;
      &__icon {
        color: gray;
        @include if-device(first, 1024) {
          font-size: 14px;
        }
      }

      & > p {
        font-family: $english-regular;
        font-size: 18px;
        @include if-device(first, 1024) {
          font-size: 14px;
        }
      }
    }
  }
}
