@import "../../../public/styles/variables";
.label {
  position: absolute;
  display: inline-block;
  padding: 2px 15px;
  background-color: $first-color;
  border-radius: 10px;
  font-size: 14px;
  top: 0px;
  color: $white;
  font-family: $english-regular;
}

.input {
  width: 100%;
  background: white;
  border: 1px solid $typo-one;
  padding: 15px 20px;
  border-radius: 20px;
  margin-top: 15px;
  color: $typo-two;
  font-size: $font2;

  &::placeholder {
    color: rgb(170, 170, 170);
  }
  &::-moz-placeholder {
    color: rgb(170, 170, 170);
  }
  &:focus {
    outline: none;
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}
