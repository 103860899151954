@import "../../../public/styles/breakpoints";
@import "../../../public/styles/variables";

.thankYou {
  margin: 50px 0;

  & > h1 {
    font-family: $english-regular;
    text-align: center;
    margin-top: -50px;
    margin-bottom: 5px;

    @include if-device(laptop) {
      margin-top: -20px;
    }

    @include if-device(third, 768) {
      font-size: 20px;
      margin-top: -20px;
    }
  }

  & > p {
    font-family: $english-regular;
    text-align: center;
    @include if-device(third, 768) {
      font-size: 16px;
    }
  }

  &__animCont {
    height: 40%;
    width: 40%;
    margin: 0 auto;
    @include if-device(phone) {
      height: 90%;
      width: 90%;
    }

    @include if-device(tablet) {
      height: 70%;
      width: 70%;
    }

    @include if-device(laptop) {
      height: 50%;
      width: 50%;
    }

    &__animation {
      height: 100%;
      width: 100%;
    }
  }

  &__btn {
    width: 200px;
    margin: 0 auto;

    @include if-device(third, 768) {
      width: 150px;
    }
  }
}
