@import "../../../public/styles/variables";
.blogs {
  margin: 50px 0;
  display: flex;
  align-items: center;
  flex-direction: column;
  row-gap: 50px;

  &__cont {
    box-shadow: $shadow;
    padding: 20px;
    width: 100%;

    &__card {
      width: 100%;
    }
  }
}
