@import "../../../public/styles/variables";

.locationCard {
  width: 100%;
  //   border: 1px solid $typo-two;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  border-radius: 10px;
  padding-bottom: 15px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;

  &__img {
    width: 70px;
    height: 70px;
    & > img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  &__title {
    color: $typo-two;
  }
}
