@import "../../../public/styles/variables";
@import "../../../public/styles/breakpoints";

.whyChooseUs {
  padding: 0 0 50px;

  @include if-device(phone) {
    padding: 0 0 150px;
  }
  @include if-device(tablet) {
    padding: 0 0 150px;
  }
  @include if-device(laptop) {
    padding: 0 0 150px;
  }

  &__title {
    &__h1 {
      font-size: 45px;
      font-family: "Pacifico", cursive;
      color: $third-color;
      font-weight: 500;
      text-align: center;

      @include if-device(phone) {
        font-size: 30px;
      }
      @include if-device(tablet) {
        font-size: 30px;
      }

      @include if-device(laptop) {
        font-size: 36px;
      }
    }

    &__p {
      font-size: 30px;
      font-family: "Pacifico", cursive;
      color: $third-color;
      font-weight: 500;
      text-align: center;
      @include if-device(phone) {
        font-size: 22px;
      }
      @include if-device(tablet) {
        font-size: 22px;
      }
    }
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  &__content {
    display: flex;
    align-items: flex-start;
    gap: 20px;
    margin-top: 50px;

    @include if-device(laptop) {
      flex-wrap: wrap;
    }

    @include if-device(tablet) {
      flex-wrap: wrap;
    }

    @include if-device(phone) {
      flex-wrap: wrap;
    }

    &__left {
      width: 30%;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      place-items: center;

      @include if-device(desktop) {
        grid-template-columns: repeat(auto-fit, minmax(130px, 1fr));
      }

      @include if-device(laptop) {
        grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
        width: 100%;
      }
      @include if-device(tablet) {
        grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
        width: 100%;
      }
      @include if-device(phone) {
        width: 100%;
      }
    }
    &__right {
      width: 70%;

      @include if-device(laptop) {
        width: 100%;
      }
      @include if-device(tablet) {
        width: 100%;
      }
      @include if-device(phone) {
        width: 100%;
      }
    }

    &__left {
      // display: flex;
      // align-items: center;
      // flex-wrap: wrap;
      gap: 20px;
      justify-content: center;
      @include if-device(laptop) {
        justify-content: center;
      }

      @include if-device(phone) {
        gap: 10px;
      }
    }

    &__right {
      display: flex;
      align-items: flex-start;
      justify-content: center;
      flex-direction: column;
      padding: 0 10px;

      &__title {
        font-size: 30px;
        text-align: left;
        color: $second-color;
        margin-bottom: 20px;
        color: $first-color;
      }

      &__desc {
        font-size: 22px;
        margin-top: -5px;
        text-transform: capitalize;
        color: $second-color;
        font-weight: 500;
        font-family: $english-regular;
        margin: 10px 0;

        @include if-device(phone) {
          font-size: 18px;
        }
      }
    }
  }
}
