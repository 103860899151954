@import "../../../../public/styles/variables";

.charges {
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  &__left {
    &__total {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 5px 0;
      background-color: $first-color;
      height: 45px;
      padding: 0 10px;
      & > p {
        text-transform: none !important;
        font-family: $english-regular;
        color: $white;
        text-transform: capitalize;
      }
    }
    & > h2 {
      text-transform: capitalize;
      font-family: $english-regular;
      color: $first-color;
      font-size: 20px;
      margin: 0;
      margin-bottom: 5px;
    }

    & > p {
      text-transform: capitalize;
      font-family: $english-regular;
      color: $black;
      font-size: 20px;
      margin: 0;
    }

    &__ul {
      list-style-type: none;
      line-height: 1.4;
      min-width: 200px;

      &__li {
        display: flex;
        align-items: center;
        justify-content: space-between;
        &__span {
          text-transform: none !important;
          color: $typo-one !important;
        }
        & > span {
          font-family: $english-regular;
          color: $black;
          text-transform: capitalize;
          font-size: 14px;
        }
      }
    }
  }
}
