@import "../../../public/styles/variables";

.loader {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 25px;

  &__box {
    width: 15px;
    height: 15px;
    margin: 0 8px;
    border-radius: 50%;
    animation: jump_4123 1s ease-in-out infinite;

    &:nth-child(1) {
      background-color: $white;
      animation-delay: 0.2s;
    }
    &:nth-child(2) {
      background-color: $white;
      animation-delay: 0.4s;
    }
    &:nth-child(3) {
      background-color: $white;
      animation-delay: 0.6s;
    }
    &:nth-child(4) {
      background-color: $white;
      animation-delay: 0.8s;
    }
  }
}

@keyframes jump_4123 {
  0%,
  100% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-10px);
  }
}
