@import "../../../public/styles/variables";
@import "../../../public/styles/breakpoints";

.header {
  border-top: 5px solid $first-color;
  border-bottom: 1px solid #eee;
  box-shadow: $shadow;
  top: 0;
  background-color: $white;
  z-index: 99;
  transition: all 0.3s ease-in-out;

  &__searchMobile {
    display: none;

    @include if-device(phone) {
      display: block;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 10px 0;
      padding: 0 20px;
    }

    @include if-device(tablet) {
      display: block;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 10px 0;
      padding: 0 20px;
    }
    @include if-device(laptop) {
      display: block;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 10px 0;
      padding: 0 20px;
    }
    @include if-device(desktop) {
      display: block;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 10px 0;
      padding: 0 20px;
    }
  }
}

.desktopNav {
  height: 70px;
  display: flex;
  align-items: center;
  padding: 0 30px;
  justify-content: space-between;
  @include if-device(phone) {
    padding: 0 20px;
  }
  &__topRight {
    padding: 5px 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    display: none;
    @include if-device(phone) {
      display: flex;
    }
  }
  &__left {
    display: flex;
    align-items: center;
    column-gap: 30px;
    &__search {
      @include if-device(phone) {
        display: none;
      }
      @include if-device(tablet) {
        display: none;
      }
      @include if-device(laptop) {
        display: none;
      }
      @include if-device(desktop) {
        display: none;
      }
    }
  }

  &__mid {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__right {
    display: flex;
    align-items: center;
    column-gap: 20px;
    justify-content: flex-end;
    width: 25%;
    @include if-device(phone) {
      column-gap: 10px;
    }
    &__location {
      display: flex;
      height: 40px;
      width: 150px;
      align-items: center;
      column-gap: 5px;
      justify-content: center;
      border-radius: 10px;
      cursor: pointer;
      padding: 0 10px;
      transition: all 0.2s ease-in-out;
      position: relative;
      z-index: 0;

      &:hover {
        background-color: $border-color;
      }
      &:hover &__icon {
        color: $white;
      }
      &:hover &__para {
        color: $white !important;
      }
      &__icon {
        color: $first-color;
        font-size: $icon-size;
        position: relative;
        z-index: 1;
        transition: all 0.2s ease-in-out;
        @include if-device(phone) {
          font-size: 18px;
        }
      }
      &__para {
        color: $first-color;
        font-size: $font-size;
        font-family: $english;
        font-weight: 500;
        transition: all 0.2s ease-in-out;
        @include if-device(phone) {
          font-size: 16px;
        }
      }
    }
    &__profile {
      width: 55px;
      height: 55px;
      cursor: pointer;
      border-radius: 50%;
      overflow: hidden;
      @include if-device(phone) {
        display: none;
      }
      @include if-device(tablet) {
        display: none;
      }
      @include if-device(laptop) {
        display: none;
      }
      & > img {
        width: 100%;
        height: 100%;
      }
    }
    &__login {
      display: flex;
      align-items: center;
      column-gap: 10px;
      border: 1px solid $border-color;
      height: 40px;
      width: 120px;
      justify-content: center;
      border-radius: 10px;
      cursor: pointer;
      padding: 0 10px;
      background-color: $first-color;
      @include if-device(phone) {
        height: 35px;
        display: none;
      }
      @include if-device(tablet) {
        display: none;
      }
      @include if-device(laptop) {
        display: none;
      }
      &__icon {
        color: $white;
        font-size: $icon-size;
      }
      & > p {
        font-size: $font-size;
        color: $white;
        font-family: $english;
        font-weight: 500;
        @include if-device(phone) {
          font-size: 18px;
        }
      }
    }
  }
  &__menuBar {
    width: 40px;
    height: 40px;
    overflow: hidden;
    cursor: pointer;
    display: none;
    & > img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
    @include if-device(phone) {
      display: block;
    }
    @include if-device(tablet) {
      display: block;
    }
    @include if-device(laptop) {
      display: block;
    }
  }
}

.closeModal {
  animation: slideOut 0.2s forwards;
  transition: transform 0.3s ease-out;
}

@keyframes slideOut {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-50px);
  }
}
