@import "../../../../public/styles/variables";
.table {
  &__tbody {
    &__tr {
      &__box {
        width: 30%;
        background-color: $first-color;
        color: $white;
        height: 40px;
        font-family: $english-regular;
        font-weight: 600;
        letter-spacing: 1px;
        font-size: 16px;
      }

      &__box1 {
        width: 5%;
        background-color: $first-color;
        height: 40px;
        font-family: $english-regular;
        font-weight: 600;
        letter-spacing: 1px;
        color: $white;
        font-size: 16px;
      }

      &__box2 {
        width: 10%;
        background-color: $first-color;
        height: 40px;
        font-family: $english-regular;
        font-weight: 600;
        letter-spacing: 1px;
        color: $white;
        font-size: 16px;
      }

      &__box3 {
        width: 10%;
        background-color: $first-color;
        height: 40px;
        font-family: $english-regular;
        font-weight: 600;
        letter-spacing: 1px;
        color: $white;
        font-size: 16px;
      }
    }

    &__tr {
      text-align: center;
      &__data {
        width: 30%;
        background-color: rgba(251, 224, 129, 0.644);
        color: $black;
        height: 40px;
        font-family: $english-regular;
        font-weight: 400;
        letter-spacing: 1px;
        font-size: 16px;
      }

      &__data1 {
        width: 5%;
        background-color: rgba(251, 224, 129, 0.644);
        height: 40px;
        font-family: $english-regular;
        font-weight: 400;
        letter-spacing: 1px;
        color: $black;
        font-size: 16px;
      }

      &__data2 {
        width: 10%;
        background-color: rgba(251, 224, 129, 0.644);
        height: 40px;
        font-family: $english-regular;
        font-weight: 400;
        letter-spacing: 1px;
        color: $black;
        font-size: 16px;
      }

      &__data3 {
        width: 10%;
        background-color: rgba(251, 224, 129, 0.644);
        height: 40px;
        font-family: $english-regular;
        font-weight: 400;
        letter-spacing: 1px;
        color: $black;
        font-size: 16px;
      }
    }
  }
}
