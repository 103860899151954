@import "../../../public/styles/variables";

.terms {
  box-shadow: $shadow;
  margin: 50px 0;
  padding: 20px;

  ul {
    list-style-type: none;
  }

  li {
    font-family: $english-regular !important;
    font-size: 16px !important;
    text-transform: capitalize;
    font-weight: 400 !important;
    color: $typo-one !important;
    line-height: 1.5;
  }
  & > h2 {
    font-family: $english;
    font-size: 1.8rem;
    font-weight: 500;
    margin-bottom: 20px;
    text-align: center;
  }

  & > p {
    font-family: $english-regular;
    font-size: 16px;
    text-transform: capitalize;
    font-weight: 400 !important;
    color: $typo-one !important;
    line-height: 1.5;
    & > p {
      font-family: $english-regular !important;
      font-size: 16px !important;
      text-transform: capitalize;
      font-weight: 400 !important;
      color: $typo-one !important;
      line-height: 1.5;

      & > span {
        font-family: $english-regular !important;
        font-size: 16px !important;
        text-transform: capitalize;
        font-weight: 400 !important;
        color: $typo-one !important;
        line-height: 1.5;

        & > span {
          font-family: $english-regular !important;
          font-size: 16px !important;
          text-transform: capitalize;
          font-weight: 400 !important;
          color: $typo-one !important;
          line-height: 1.5;
          & > a {
            font-family: $english-regular !important;
            font-size: 16px !important;
            text-transform: capitalize;
            font-weight: 500 !important;
            color: blue;
          }
          & > span {
            font-family: $english-regular !important;
            font-size: 16px !important;
            text-transform: capitalize;
            font-weight: 400 !important;
            color: $typo-one !important;
            line-height: 1.5;
          }
        }
      }
    }
  }
}
