@import "../../../public/styles/variables";
@import "../../../public/styles/breakpoints";
.form {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  overflow: hidden;
  padding: 20px;
  background: #f8f9fd;
  background: linear-gradient(0deg, rgb(255, 255, 255) 0%, rgb(244, 247, 251) 100%);
  border-radius: 40px;
  border: 5px solid rgb(255, 255, 255);
  box-shadow: rgba(254, 102, 132, 0.8784313725) 0px 30px 30px -20px;
  max-width: 450px;
  @include if-device(phone) {
    width: 95%;
    padding: 25px 15px;
  }
  @include if-device(tablet) {
    width: 75%;
  }
  @include if-device(laptop) {
    width: 60%;
  }
  @include if-device(desktop) {
    width: 50%;
  }
  &__close {
    position: absolute;
    right: 10px;
    top: 10px;
    background-color: $third-color;
    height: 30px;
    width: 30px;
    display: grid;
    place-items: center;
    border-radius: 50%;
    cursor: pointer;
    font-weight: 600;
    transition: 0.3s ease;
    color: $white;
    &:hover {
      background-color: red;
    }
  }

  &__info {
    margin-bottom: 10px;
    &__title {
      color: $typo-one;
      font-size: 1.5rem;
      line-height: 1.8rem;
      letter-spacing: 1.2px;
      font-weight: 600;
      @include if-device(phone) {
        font-size: 1.2rem;
      }
    }
    &__description {
      color: $typo-one;
      margin-top: 10px;
      font-size: 15px;
    }
  }

  &__inputs {
    display: flex;
    justify-content: space-between;
    gap: 10px;

    & > input {
      height: 2.5em;
      width: 2.5em;
      outline: none;
      text-align: center;
      font-size: 1.5em;
      color: $typo-two;
      border-radius: 0.3em;
      border: 1px solid $typo-one;
      background-color: rgb(255 255 255 / 0.05);

      &:focus {
        border: 1px solid $first-color;
      }
      @include if-device(phone) {
        height: 2em;
        width: 2em;
      }
    }
  }

  &__resend {
    color: $typo-two;
    margin-top: 10px;
    font-size: 15px;
    text-align: center;

    &__resendAction {
      text-decoration: none;
      cursor: pointer;
      margin-left: 6px;
      color: $third-color;
      text-transform: capitalize;
      letter-spacing: 1.2px;
      font-weight: 500;

      &:hover {
        text-decoration: underline $third-color;
      }
    }
  }

  &__validate {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    text-decoration: none;
    background-color: $third-color;
    padding: 10px 20px;
    margin: 8px 0 0 0;
    font-size: 13px;
    font-weight: 600;
    border-radius: 10px;
    transition: 0.3s ease;
    font-family: $english-regular;
    letter-spacing: 1.2px;
    color: $white;
    cursor: pointer;

    &:hover {
      background-color: $first-color;
    }
  }
}
