@import "../../../public/styles/variables";
@import "../../../public/styles/breakpoints";
.btn {
  display: block;
  width: 100%;
  font-weight: bold;
  background: linear-gradient(45deg, $third-color 0%, rgba(254, 102, 132, 0.8784313725) 100%);
  color: white;
  padding-block: 15px;
  margin: 20px auto;
  border-radius: 20px;
  font-size: $font2;
  box-shadow: rgba(254, 102, 132, 0.8784313725) 0px 20px 10px -15px;
  border: none;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  font-family: $english-regular;
  letter-spacing: 1px;

  @include if-device(third, 768) {
    padding-block: 12px;
    font-size: 16px;
  }

  &:hover {
    transform: scale(1.03);
    box-shadow: rgba(254, 102, 132, 0.8784313725) 0px 23px 10px -20px;
  }

  &:active {
    transform: scale(0.95);
    box-shadow: rgba(254, 102, 132, 0.8784313725) 0px 15px 10px -10px;
  }
}
