.logo {
  height: 60px;
  width: 60px;
  cursor: pointer;

  & > img {
    height: 100%;
    width: 100%;
  }
}
