@import "../../../public/styles/variables";
@import "../../../public/styles/breakpoints";

.productView {
  &__left {
    width: 100%;
    display: flex;
    align-items: center;
    column-gap: 10px;
  }

  &__bigView {
    margin-top: 3px;
    width: 100%;
    height: 450px;
    background-color: $white;
    border: 1px solid #333;
    padding: 2px;
    overflow: hidden;
    box-shadow: $shadow;
    margin-bottom: 10px;
    position: relative;

    @include if-device(phone) {
      aspect-ratio: 1/1;
      height: auto;
    }

    &__rating {
      position: absolute;
      top: 2px;
      right: 2px;
      height: 70px;
      width: 70px;
      background-color: #000000a4;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      border-radius: 0 0 0 50%;
      padding: 10px;
      row-gap: 5px;

      &__icon {
        font-size: 30px;
        color: #ffd700;
      }

      & > p {
        font-size: 18px;
        color: $white;
        font-family: $english-regular;
      }
    }

    & > img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }
}

.active {
  border: 3px solid #333 !important;
  padding: 2px;
}
