@import "../../../public/styles/variables";
@import "../../../public/styles/breakpoints";

.allList {
  padding: 30px 100px 50px;
  @include if-device(phone) {
    padding: 30px 15px !important;
  }

  &__title {
    display: flex;
    align-items: center;
    font-family: $english-regular;
    font-size: 20px;
    margin-bottom: 30px;
    color: $typo-two;
    text-transform: capitalize;

    &__angle {
      font-weight: 400;
      color: $third-color;
    }
  }
  &__body {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;
    @include if-device(phone) {
      grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    }
    @include if-device(tablet) {
      grid-template-columns: repeat(2, 1fr);
    }
    @include if-device(laptop) {
      grid-template-columns: repeat(3, 1fr);
    }
  }
}
