@import "../../../public/styles/breakpoints";
.notFound {
  height: 99vh;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  &__animCont {
    height: 100%;
    width: 80%;
    margin: 0 auto;

    @include if-device(phone) {
      width: 100%;
      height: auto;
    }

    &__animation {
      width: 100%;
      height: 100%;
    }
  }

  &__btnCont {
    position: absolute;
    width: 200px;
    left: 30%;
    top: 60%;

    @include if-device(laptop) {
      left: 18%;
      top: 60%;
    }
    @include if-device(desktop) {
      left: 26%;
      top: 60%;
    }
    @include if-device(tablet) {
      left: 18%;
      top: 60%;
      width: 180px;
    }

    @include if-device(phone) {
      position: relative;
      left: 0;
      top: 0;
    }
  }
}
