@import "../../../../public/styles/variables";
@import "../../../../public/styles/breakpoints";
.slot {
  border: 1px solid $typo-one;
  padding: 20px;
  box-shadow: $shadow;
  border-radius: 5px;
  margin-top: 2px;

  &__title {
    margin-bottom: 10px;
    & > h1 {
      font-family: $english-regular;
      font-size: 24px;
    }
  }

  &__price {
    margin-bottom: 20px;
    &__full {
      font-family: $english-regular;
      margin-bottom: 10px;
      color: $first-color;
      & > del,
      & > span {
        font-family: $english-regular;
        font-size: 16px;
        font-weight: 400;
        color: $typo-one;
      }
    }
  }

  &__datetime {
    display: "flex";
    align-items: "flex-start";
    justify-content: "space-between";
    flex-direction: "column";

    &__timeslot {
      padding: 0 20px 20px;
      background-color: white;
      border-radius: 10px;
      z-index: 2;
      position: relative;

      &__ul {
        list-style-type: none;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 10px;

        @include if-device(phone) {
          grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
        }
        @include if-device(tablet) {
          grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
        }

        @include if-device(laptop) {
          grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
        }

        &__li {
          display: flex;
          align-items: center;
          height: 30px;
          background-color: $first-color;
          padding: 0 10px;
          column-gap: 5px;
          border-radius: 15px;
          cursor: pointer;

          & > input {
            height: 20px;
            width: 20px;
            accent-color: red;
          }

          & > p {
            font-size: 16px;
            font-family: $english-regular;
            font-weight: 600;
            letter-spacing: 1px;
            color: $white;

            @include if-device(phone) {
              font-size: 14px;
            }
            @include if-device(tablet) {
              font-size: 14px;
            }
            @include if-device(laptop) {
              font-size: 14px;
            }
          }
        }
      }
    }

    & > p {
      margin-bottom: 20px;
      text-align: center;
      font-size: 18px;
    }

    &__pincode {
      display: flex;
      align-items: center;
      column-gap: 20px;
      @include if-device(phone) {
        display: block;
      }
      &__left,
      &__right {
        width: 50%;
        @include if-device(phone) {
          width: 100% !important;
        }
      }

      &__left {
        position: relative;
        &__ul {
          width: 100%;
          position: absolute;
          top: 50px;
          left: 0;
          background-color: $white;
          list-style-type: none;
          z-index: 3;
          max-height: 300px;
          overflow: hidden;
          border-radius: 10px;
          border: 1px solid $first-color;
          box-shadow: $shadow;
          // overflow-y: scroll;

          &::-webkit-scrollbar {
            width: 10px;
            // border-radius: 0 10px 10px 0;
          }

          &::-webkit-scrollbar-track {
            box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.3);
            // outline: 1px solid slategrey;
            // border-radius: 0 10px 10px 0;
          }

          &::-webkit-scrollbar-thumb {
            background-color: $first-color;
            // border-radius: 0 10px 10px 0;
          }
          & > li {
            height: 50px;
            background-color: $white;
            padding: 0 20px;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            border-bottom: 1px solid #f0f0f1;

            &:last-child {
              border-bottom: none;
            }

            &:hover {
              background-color: $first-color;
              color: $white;
            }
          }
        }
      }
    }
  }
}
