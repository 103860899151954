@import "../../../public/styles/variables";
@import "../../../public/styles/breakpoints";

.details {
  padding: 20px;

  &__loader {
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 50px;
  }

  & > h1 {
    text-align: left;
    font-family: $english-regular;
    font-size: 28px;
    margin-top: -13px;
    text-align: center;

    @include if-device(third, 768) {
      font-size: 20px;
    }
  }

  &__refund {
    margin: 10px 0 20px;
    &__tab {
      & > ul {
        display: flex;
        align-items: center;
        list-style-type: none;
        border-radius: 10px;
        overflow: hidden;

        & > li {
          height: 40px;
          width: 100%;
          background-color: $typo-one;
          display: flex;
          align-items: center;
          justify-content: center;
          color: $white;
          cursor: pointer;
        }
      }
    }
  }

  &__bank {
    margin-top: 10px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
    @include if-device(phone) {
      grid-template-columns: repeat(1, 1fr);
    }
  }

  &__bank2 {
    margin-top: 10px;
  }

  & > p {
    display: flex;
    align-items: center;
    column-gap: 10px;
    margin: 10px 0;
    cursor: pointer;

    & > input {
      height: 20px;
      width: 20px;
      accent-color: red;
    }

    & > span {
      font-family: $english-regular;
      flex: 1;
      text-transform: capitalize;
    }
  }
}

.active {
  background-color: $first-color !important;
}
