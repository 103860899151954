@import "../../../../public/styles/breakpoints";

.title {
  font-size: 22px;
  font-weight: 500;
  margin-bottom: 40px;
  color: #fff;
  text-transform: capitalize;

  @include if-device(laptop) {
    font-size: 23px;
  }
  @include if-device(tablet) {
    font-size: 23px;
  }
  @include if-device(phone) {
    font-size: 24px;
  }
}
