@import "../../../public/styles/variables";
.input2 {
  p {
    margin-top: 0;
    margin-bottom: 0.5rem;
    font-weight: 500;
    line-height: 1.2;
    font-size: 14px;
    font-family: $english-regular;
    z-index: 1;
    color: #212529;
  }
  input {
    display: block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    height: 40px;
    font-weight: 400;
    color: #212529;
    appearance: none;
    font-family: $english-regular;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #dee2e6;
    border-radius: 0.375rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    &:focus {
      outline: none;
      border: 1px solid #aaa;
    }
  }
}
