@import "../../../../public/styles/variables";
@import "../../../../public/styles/breakpoints";

.chooseCard {
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.13), 0 1px 3px rgba(0, 0, 0, 0.2);
  width: 100%;
  height: 180px;
  padding: 20px;
  display: flex;
  align-items: center;
  // border-radius: 27% 73% 73% 27% / 57% 55% 45% 43%;
  border-radius: 20px;
  flex-direction: column;
  background-color: $first-color;

  @include if-device(desktop) {
    width: 100%;
    height: 140px;
    padding: 10px;
  }
  @include if-device(laptop) {
    width: 170px;
    height: 170px;
  }

  @include if-device(tablet) {
    width: 100%;
    height: 140px;
    padding: 10px;
  }

  @include if-device(phone) {
    width: 100%;
    height: 140px;
    padding: 10px;
  }

  &__img {
    width: 60px;
    height: 60px;
    margin-bottom: 10px;

    @include if-device(desktop) {
      width: 50px;
      height: 50px;
    }

    @include if-device(laptop) {
      width: 50px;
      height: 50px;
    }

    @include if-device(tablet) {
      width: 50px;
      height: 50px;
    }
    @include if-device(phone) {
      width: 30px;
      height: 30px;
    }

    & > img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  &__title {
    font-size: 16px;
    text-transform: capitalize;
    color: $second-color;
    text-align: center;

    @include if-device(desktop) {
      font-size: 14px;
    }

    @include if-device(laptop) {
      font-size: 16px;
    }
    @include if-device(tablet) {
      font-size: 14px;
    }
    @include if-device(phone) {
      font-size: 16px;
    }
  }
}
