@import "../../../../public/styles/variables";
@import "../../../../public/styles/breakpoints";

.reviews {
  border: 1px solid $typo-one;
  padding: 20px;
  box-shadow: $shadow;
  border-radius: 5px;
  &__content {
    display: flex;
    align-items: flex-start;
    column-gap: 20px;

    @include if-device(phone) {
      flex-wrap: wrap;
      row-gap: 20px;
    }

    &__left {
      width: 50%;

      @include if-device(phone) {
        width: 100%;
      }

      & > h5 {
        font-size: 18px;
        font-family: $english-regular;
        margin-bottom: 10px;
      }

      & > p {
        margin-bottom: 10px;
      }

      & > textarea {
        width: 100%;
        height: 100px;
        margin-bottom: 20px;
        border: 1px solid $typo-one;
        padding: 10px;
        font-size: 16px;

        &:focus {
          outline: none;
        }
      }

      &__star {
        display: flex;
        align-items: center;
        column-gap: 5px;
        margin-bottom: 20px;
        &__icon {
          font-size: 24px;
          cursor: pointer;
        }
      }
    }

    &__right {
      width: 50%;
      @include if-device(phone) {
        width: 100%;
      }

      & > p {
        width: 100%;
        margin-top: 50px;
        display: inline-block;
        cursor: pointer;
        color: $typo-two;
        font-family: $english-regular;
        transition: all 0.2s ease;
        text-align: end;
        &:hover {
          color: $first-color;
        }
      }
    }
  }
}
