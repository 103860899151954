@import "../../../../public/styles/breakpoints";

.card {
  width: 100%;
  height: 400px;
  position: relative;
  overflow: hidden;
  cursor: pointer;

  &__imgCont {
    width: 100%;
    height: 100%;

    &__img {
      height: 100%;
      transition: all 0.3s ease-in-out;
      width: 100%;
      transform: scale(1);
      object-fit: cover;
    }
    overflow: hidden;
  }

  &__content {
    position: absolute;
    bottom: 0;
    width: 100%;
    color: #fff;
    text-align: center;
    background-image: linear-gradient(rgba(6, 6, 6, 0), rgba(28, 25, 86, 0.8313725490196079));
    padding-top: 85px;
    padding-bottom: 20px;

    &__italic {
      font-size: 24px;
      font-family: "Pacifico", cursive;

      @include if-device(phone) {
        font-size: 16px;
      }

      @include if-device(tablet) {
        font-size: 18px;
      }

      @include if-device(laptop) {
        font-size: 18px;
      }
      @include if-device(desktop) {
        font-size: 20px;
      }
    }

    &__bold {
      font-size: 40px;
      font-weight: 500;
      @include if-device(phone) {
        font-size: 20px;
      }
      @include if-device(tablet) {
        font-size: 22px;
      }

      @include if-device(laptop) {
        font-size: 22px;
      }
      @include if-device(desktop) {
        font-size: 22px;
      }
    }
  }

  &:hover &__imgCont {
    &__img {
      transform: scale(1.2) rotate(5deg);
    }
  }
}
