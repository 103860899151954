@import "../../../../public/styles/variables";

.card {
  background: $white;
  overflow: hidden;
  box-shadow: 0 8px 24px rgba(149, 157, 165, 0.2);
  border-radius: 16px;
  padding-top: 50px;
  margin: 0 10px;
  width: 100%;
  height: 500px;
  position: relative;
  text-align: center;
  box-shadow: $shadow;

  &__quotes {
    font-size: 30px;
    margin-bottom: 10px;
    color: $first-color;
  }

  &__title {
    margin-bottom: 20px;
    font-size: 16px;
    font-weight: 500;
    padding: 0 30px;
    font-family: $english-regular;
    color: $typo-two;
  }

  &__bottom {
    position: absolute;
    height: 200px;
    width: 100%;
    bottom: 0;

    &__user {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      bottom: 50px;
      left: 50%;
      transform: translateX(-50%);
      margin: 0 auto;
      z-index: 1;
      & > img {
        width: 80px;
        border-radius: 50%;
        box-shadow: 0 0 1px rgba(0, 0, 0, 0.13), 0 1px 3px rgba(0, 0, 0, 0.2);
        border: 4px solid #fff;
      }
    }

    &__ocean {
      height: 5%;
      width: 100%;
      position: absolute;
      bottom: 100px;
      left: 0;

      & > img {
        width: 100%;
      }
      // background: #015871;

      // &__wave {
      //   background: url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/85486/wave.svg) repeat-x;
      //   position: absolute;
      //   top: -198px;
      //   width: 6400px;
      //   height: 198px;
      //   animation: wave 7s cubic-bezier(0.36, 0.45, 0.63, 0.53) infinite;
      //   transform: translate3d(0, 0, 0);

      //   &:nth-of-type(2) {
      //     top: -175px;
      //     animation: wave 7s cubic-bezier(0.36, 0.45, 0.63, 0.53) -0.125s infinite, swell 7s ease -1.25s infinite;
      //     opacity: 1;
      //   }
      // }
    }
  }
}

@keyframes wave {
  0% {
    margin-left: 0;
  }
  100% {
    margin-left: -1600px;
  }
}

@keyframes swell {
  0%,
  100% {
    transform: translate3d(0, -25px, 0);
  }
  50% {
    transform: translate3d(0, 5px, 0);
  }
}
