@import "../../../../public/styles/variables";
.top {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  &__left {
    position: relative;
    width: 75%;
    &__content {
      position: absolute;
      top: -65px;
      left: -100px;
      display: flex;
      align-items: center;
      column-gap: 10px;
      background-color: black;
      padding: 20px 20px 20px 100px;
      transform: skew(-50deg);
      width: 80%;
      &::before {
        position: absolute;
        content: "";
        height: 70%;
        padding: 20px 0 20px;
        background-color: rgba(19, 19, 19, 0.915);
        width: 50px;
        top: -20px;
        right: -50px;
      }

      &::after {
        position: absolute;
        content: "";
        height: 50%;
        padding: 20px 0 20px;
        background-color: rgba(44, 43, 43, 0.915);
        width: 50px;
        top: -20px;
        right: -100px;
      }

      &__imgCont {
        height: 80px;
        width: 80px;
        transform: skew(50deg) !important;
        & > img {
          height: 100%;
          width: 100%;
        }
      }

      &__comp {
        color: #fff;
        transform: skew(50deg) !important;

        & > h1 {
          text-transform: uppercase;
          font-family: $english-regular;
          color: $first-color;
          font-size: 40px;
          margin: 0;
        }
      }
    }
  }

  &__right {
    width: 30%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    &__content {
      & > h1 {
        text-transform: uppercase;
        font-family: $english-regular;
        color: $first-color;
        font-size: 40px;
        margin: 0;
      }
      &__det {
        display: flex;
        align-items: center;
        justify-content: space-between;

        & > span {
          font-size: 13px;
          font-family: $english-regular;
        }

        &__val {
          color: $typo-one;
        }
      }
    }
  }
}
