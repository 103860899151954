@import "../../../public/styles/variables";
@import "../../../public/styles/breakpoints";

.overlay {
  background-color: transparent;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 98;
}

.headerMenu {
  display: flex;
  align-items: center;
  list-style-type: none;
  column-gap: 30px;
  @include if-device(laptop) {
    display: none;
  }
  @include if-device(tablet) {
    display: none;
  }
  @include if-device(phone) {
    display: none;
  }

  &__pick {
    padding: 10px;
    color: #fff;
    background: $first-color;
    border: 1px solid $first-color;
    cursor: pointer;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    column-gap: 10px;
    transition: all 0.2s ease-in-out;
    border-radius: 10px;
    // position: relative;

    @include if-device(desktop) {
      min-width: 215px;
    }

    &__icon {
      display: flex;
      align-items: baseline;
      justify-content: center;
      transform: rotate(-90deg);
    }

    &__iconHover {
      display: flex;
      align-items: baseline;
      justify-content: center;
      transform: rotate(90deg);
    }
    &__services {
      width: 900px;
      height: 380px;
      position: absolute;
      top: 60px;
      // transform: translate(-50%);
      background-color: $white;
      border: 2px solid $first-color;
      border-radius: 10px;
      box-shadow: $shadow;
      transition: 0.3s;
      overflow: hidden;
      z-index: 99;
    }
    &:hover {
      border: 1px solid $first-color;
      background: transparent;
      color: $first-color;
    }

    &:hover &__icon {
      transform: rotate(0deg);
    }
    &:hover &__iconHover {
      transform: rotate(0deg) !important;
    }
    // &:hover &__services {
    //   visibility: visible;
    // }
  }
}

.marriage {
  transform: translateY(-20px);
  animation: popup 0.5s ease-in alternate;
}

@keyframes popup {
  0% {
  }
  100% {
  }
}
