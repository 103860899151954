@import "../../../../public/styles/variables";
@import "../../../../public/styles/breakpoints";

.knowAbout {
  padding: 20px;
  background-color: white;
  position: relative;
  border: 1px solid $typo-one;
  box-shadow: $shadow;
  border-radius: 5px;

  &__ul {
    list-style-type: none;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
    margin-top: -10px;

    @include if-device(first, 1024) {
      grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    }

    &__li {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 40px;
      border: 1px solid $typo-one;
      padding-left: 10px;
      column-gap: 5px;
      border-radius: 15px;
      cursor: pointer;

      & > p {
        font-size: 16px;
        font-family: $english-regular;
        font-weight: 500;
        letter-spacing: 1px;
        @include if-device(phone) {
          text-align: center;
          width: 100%;
          font-size: 14px;
        }
      }
    }
  }
}
