@import "../../../../public/styles/variables";
.smallViews {
  height: 80px;
  width: 80px;
  background-color: $white;
  margin: 3px 0;
  cursor: pointer;
  border: 3px solid #fff;
  padding: 2px;
  & > img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}
