@import "../../../public/styles/variables";
@import "../../../public/styles/breakpoints";

.contact {
  padding: 50px 0 80px;
  @include if-device(phone) {
    padding: 30px 0 40px !important;
  }
  @include if-device(tablet) {
    padding: 40px 0 40px !important;
  }

  &__header {
    margin-bottom: 50px;

    &__title {
      font-family: $english-regular;
      font-size: 1.2rem;
      margin: 5px 0;
      @include if-device(phone) {
        font-size: 1rem;
      }
      & > a {
        font-family: $english-regular;
        font-size: 1.2rem;
        margin: 0 5px;
        color: $first-color;
        @include if-device(phone) {
          font-size: 1.1rem;
        }
      }
    }
  }
  &__info {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    column-gap: 50px;
    @include if-device(phone) {
      flex-wrap: wrap;
      row-gap: 30px;
    }
    @include if-device(tablet) {
      flex-wrap: wrap;
      row-gap: 30px;
    }

    &__left {
      width: 50%;
      border: 1px solid $typo-one;
      padding: 20px;
      box-shadow: $shadow;
      border-radius: 5px;
      @include if-device(phone) {
        width: 100%;
      }
      @include if-device(tablet) {
        width: 100%;
      }
      & > h3 {
        font-family: $english-regular;
        font-size: 1.5rem;
        margin: 0 0 20px;
      }
      &__oneline {
        display: flex;
        align-items: center;
        column-gap: 10px;
        @include if-device(phone) {
          display: block;
        }
      }
      &__textarea {
        font-size: 1.1rem;
        padding: 1rem;
        font-family: inherit;
        color: #002247;
        border-radius: 12px;
        background-color: #f0f0f1;
        border: none;
        border-bottom: 3px solid transparent;
        width: 100%;
        min-height: 132px;
        display: block;
        transition: all 0.3s;
        font-family: $english-regular;

        &::placeholder {
          opacity: 1;
          color: #a3acb8;
          font-size: 1.1rem;
        }

        &:focus {
          outline: none;
        }
      }
    }

    &__right {
      width: 50%;
      display: flex;
      flex-direction: column;
      row-gap: 30px;
      @include if-device(phone) {
        width: 100%;
      }
      @include if-device(tablet) {
        width: 100%;
      }
      &__top {
        display: flex;
        flex-direction: column;
        row-gap: 20px;
        &__box {
          display: flex;
          align-items: flex-start;
          column-gap: 10px;

          &__icon {
            height: 50px;
            width: 50px;
            & > img {
              height: 100%;
              width: 100%;
            }
          }

          &__content {
            & > h1 {
              font-family: $english-regular;
              font-size: 1.2rem;
            }
            & > a {
              font-family: $english-regular;
              font-size: 1rem;
              color: $typo-one;
            }
          }
        }
      }

      &__bottom {
        border-radius: 10px;
        overflow: hidden;
      }
    }
  }
}
