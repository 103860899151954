@import "../../../../public/styles/variables";
@import "../../../../public/styles/breakpoints";

.knowAbout {
  padding: 20px;
  background-color: white;
  position: relative;
  border: 1px solid $typo-one;
  box-shadow: $shadow;
  border-radius: 5px;

  &__ul {
    list-style-type: none;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    margin-top: -10px;

    @include if-device(first, 1024) {
      grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    }

    &__li {
      display: flex;
      align-items: center;
      column-gap: 5px;
      border-radius: 15px;

      & > input {
        height: 20px;
        width: 20px;
        accent-color: red;
        cursor: pointer;
      }

      & > p {
        font-size: 16px;
        font-family: $english-regular;
        font-weight: 500;
        letter-spacing: 1px;
        @include if-device(first, 1024) {
          width: 100%;
          font-size: 14px;
        }
      }
    }
  }
}
