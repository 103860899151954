@import "../../../public/styles/variables";
@import "../../../public/styles/breakpoints";

.footer {
  background-image: url("../../assets/footer.webp");
  height: 400px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: relative;
  // margin-top: 100px;
  position: relative;

  @include if-device(desktop) {
    height: auto;
  }

  @include if-device(laptop) {
    height: auto;
  }

  @include if-device(tablet) {
    height: auto;
  }

  @include if-device(phone) {
    height: auto;
  }

  &__animation {
    position: absolute;
    top: -280px;
    height: 300px;
    width: 300px;
    color: $first-color;
    z-index: -1;
  }

  &__supFooter {
    padding: 50px 0 0;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    column-gap: 40px;

    &__div {
      // width: 100%;
      @include if-device(laptop) {
        width: 44%;
      }
      @include if-device(tablet) {
        width: 100%;
      }
      @include if-device(phone) {
        width: 100%;
      }
    }

    @include if-device(desktop) {
      flex-wrap: wrap;
      justify-content: flex-start;
      row-gap: 50px;
      padding-bottom: 80px;
    }

    @include if-device(laptop) {
      flex-wrap: wrap;
      justify-content: flex-start;
      row-gap: 50px;
      padding-bottom: 80px;
    }

    @include if-device(tablet) {
      flex-wrap: wrap;
      justify-content: flex-start;
      row-gap: 50px;
      padding-bottom: 80px;
    }

    @include if-device(phone) {
      flex-wrap: wrap;
      justify-content: flex-start;
      row-gap: 50px;
      padding-bottom: 80px;
      padding: 50px 0 100px;
    }
  }

  &__subFooter {
    width: 100%;
    position: absolute;
    bottom: 0;
    color: #fff;
    text-align: center;
    background: rgba(0, 0, 0, 0.2784313725490196);
    padding: 10px;

    & > p {
      @include if-device(phone) {
        display: flex;
        align-items: center;
        flex-direction: column;
      }

      @include if-device(tablet) {
        display: flex;
        align-items: center;
        flex-direction: column;
      }

      & > a {
        text-decoration: none;
        color: $first-color;
      }
    }
  }
}
