@import "../../../../public/styles/variables";
.forServices {
  // width: 100%;
  &__ul {
    list-style-type: none;
    display: flex;
    justify-content: center;
    flex-direction: column;
    row-gap: 20px;

    &__li {
      display: flex;
      align-items: center;
      column-gap: 10px;
      color: $white;
      cursor: pointer;
      transition: all 0.2s ease-in;

      &:hover {
        color: $first-color;
      }

      &:hover &__icon {
        background-color: $first-color;
        &__i {
          color: $white;
        }
      }

      &__icon {
        background-color: $white;
        height: 40px;
        width: 40px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        transition: all 0.2s ease-in;
        &__i {
          color: $second-color;
          transition: all 0.2s ease-in;
        }
      }

      & > span {
        font-size: 18px;
        font-family: $english-regular;
        font-weight: 500;
      }
    }
  }
}
