.toggler {
  width: 60px;
  //   margin: 0 20px;

  & > input {
    display: none;
  }

  & > label {
    display: block;
    position: relative;
    width: 60px;
    height: 30px;
    border: 1px solid #d6d6d6;
    border-radius: 36px;
    background: #e4e8e8;
    cursor: pointer;

    &::after {
      display: block;
      border-radius: 100%;
      background-color: #f3052d;
      content: "";
      animation-name: toggler-size;
      animation-duration: 0.15s;
      animation-timing-function: ease-out;
      animation-direction: forwards;
      animation-iteration-count: 1;
      animation-play-state: running;
    }
  }
}

.toggler label::after {
  position: absolute;
  top: 50%;
  left: 25%;
  width: 24px;
  height: 24px;
  transform: translateY(-50%) translateX(-50%);
  transition: left 0.15s ease-in-out, background-color 0.2s ease-out, width 0.15s ease-in-out, height 0.15s ease-in-out,
    opacity 0.15s ease-in-out;
}

.toggler input:checked + label::after,
.toggler input:checked + label .toggler-on,
.toggler input:checked + label .toggler-off {
  left: 75%;
}

.toggler input:checked + label::after {
  background-color: #13db2e;
  animation-name: toggler-size2;
}

.toggler .toggler-on,
.toggler .toggler-off {
  opacity: 1;
  z-index: 2;
}

.toggler input:checked + label .toggler-off,
.toggler input:not(:checked) + label .toggler-on {
  width: 0;
  height: 0;
  opacity: 0;
}

.toggler .path {
  fill: none;
  stroke: #fefefe;
  stroke-width: 7px;
  stroke-linecap: round;
  stroke-miterlimit: 10;
}

@keyframes toggler-size {
  0%,
  100% {
    width: 26px;
    height: 26px;
  }

  50% {
    width: 20px;
    height: 20px;
  }
}

@keyframes toggler-size2 {
  0%,
  100% {
    width: 26px;
    height: 26px;
  }

  50% {
    width: 20px;
    height: 20px;
  }
}
