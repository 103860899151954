@import "../../../public/styles/variables";

.invoice {
  box-shadow: $shadow;
  margin: 50px auto;
  width: 65%;
  padding: 20px 20px 80px;
  overflow: hidden;
  position: relative;

  & > p {
    text-align: center;
    margin: -5px 0 10px;
  }
}
