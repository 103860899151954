@import "../../public/styles/variables";
@import "../../public/styles/breakpoints";

.backdrop {
  z-index: 1000;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
  cursor: pointer;
}

.modal {
  z-index: 1001;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 40%;
  background-color: #fff;
  box-shadow: $shadow;
  animation: popup 0.3s ease-in alternate;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    background-color: #fff;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $first-color;
  }
  // @include if-device(desktop) {
  //   width: 70% !important;
  // }
  // @include if-device(largephone) {
  //   width: 90% !important;
  // }
  // @include if-device(tablet) {
  //   width: 90% !important;
  // }
  // @include if-device(phone) {
  //   width: 90% !important;
  //   max-height: 90%;
  // }

  @include if-device(first, 1024) {
    width: 90% !important;
    max-height: 90%;
  }
  & > button {
    position: fixed;
    z-index: 99999;
    top: 0;
  }
}

@keyframes popup {
  0% {
    top: 40%;
  }
  100% {
    top: 50%;
  }
}
