@import "../../../../public/styles/variables";

.contactUs {
  // width: 80%;

  &__ul {
    list-style: none;
    &__li {
      margin: 20px 0;
      color: $white;
      font-size: 18px;
      font-family: $english-regular;
      font-weight: 500;
      cursor: pointer;
      transition: all 0.2s ease-in;

      & > a {
        margin: 20px 0;
        color: $white;
        font-size: 18px;
        font-family: $english-regular;
        font-weight: 500;
        cursor: pointer;
        transition: all 0.2s ease-in;
        text-decoration: none;
        &:hover {
          color: $first-color;
        }
      }

      &:hover {
        color: $first-color;
      }
    }
  }
}
