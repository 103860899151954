@import "../../../public/styles/variables";

.inputCont {
  width: 100%;
  position: relative;
  margin-bottom: 20px;

  & > label {
    position: absolute;
    display: inline-block;
    padding: 2px 15px;
    background-color: $first-color;
    border-radius: 10px;
    font-size: 14px;
    top: -15px;
    color: $white;
    font-family: $english-regular;
  }

  &__input {
    font-size: 1.1rem;
    padding: 1.5rem 1rem 1.5rem;
    font-family: inherit;
    color: #002247;
    border-radius: 12px;
    background-color: #f0f0f1;
    border: none;
    border-bottom: 3px solid transparent;
    width: 100%;
    height: 50px;
    display: block;
    transition: all 0.3s;
    font-family: $english-regular;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    &::placeholder {
      opacity: 1;
      color: #a3acb8;
      font-size: 1.1rem;
    }

    &:focus {
      outline: none;
    }
  }
}
