@import "../../../../public/styles/variables";

.bottom {
  height: 80px;
  width: 100%;
  padding: 0 20px;
  background-color: $black;
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &__left {
    list-style-type: none;
    line-height: 1.3;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 70%;

    &__li {
      display: flex;
      align-items: center;
      justify-content: center;
      column-gap: 10px;
      &__span {
        text-transform: none !important;
        color: $typo-one !important;
        font-size: 14px;
      }
      &__icon {
        font-family: $english-regular;
        color: $white;
        text-transform: capitalize;
        height: 25px;
        width: 25px;
        background-color: $first-color;
        border-radius: 5px;
        transform: rotate(45deg);
        display: flex;
        align-items: center;
        justify-content: center;

        &__i {
          rotate: -45deg;
          font-size: 14px;
        }
      }
    }
  }
  & > h1 {
    text-transform: capitalize;
    font-family: $english-regular;
    color: $first-color;
    font-size: 18px;
    margin: 0;
    margin-bottom: 5px;
    flex: 1;
    text-align: right;
  }
}
