@import "../../../public/styles/variables";
@import "../../../public/styles/breakpoints";

.locationPicker {
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.7);
  height: 100%;
  width: 100%;
  z-index: 998;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.8s ease-in-out;

  &__body {
    width: 40%;
    background-color: $white;
    height: 80%;
    border-radius: 10px;
    overflow-y: scroll;
    z-index: 999;
    position: relative;

    &::-webkit-scrollbar {
      width: 10px;
      border-radius: 0 10px 10px 0;
    }

    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      // outline: 1px solid slategrey;
      border-radius: 0 10px 10px 0;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $first-color;
      border-radius: 0 10px 10px 0;
    }

    @include if-device(phone) {
      width: 90%;
    }
    @include if-device(tablet) {
      width: 90%;
    }
    @include if-device(laptop) {
      width: 70%;
    }
    @include if-device(desktop) {
      width: 60%;
    }

    &__loader {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    &__top {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 15px 20px 15px 30px;
      border-bottom: 1px solid $first-color;
      background-color: $first-color;
      color: $white;
      border-radius: 10px 0 0 0;
      z-index: 100;
      position: sticky;
      top: 0;
      box-shadow: $shadow;
      @include if-device(phone) {
        padding: 15px 10px 15px 10px;
      }
      & > h1 {
        font-size: 26px;
        @include if-device(phone) {
          font-size: 17px;
        }
      }

      &__icon {
        font-size: 25px;
        cursor: pointer;
        @include if-device(phone) {
          font-size: 19px;
        }
      }
    }

    &__para {
      padding: 15px 0;
      color: $typo-two;
      text-align: center;
    }

    &__bottom {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      padding: 0 30px 30px 30px;
      gap: 10px;
      overflow-y: auto;
      padding-top: 5px;
      @include if-device(phone) {
        padding: 0 20px 30px 20px;
      }
      &__card {
        width: 31.6%;
        cursor: pointer;
        @include if-device(phone) {
          width: 47%;
        }
        @include if-device(tablet) {
          width: 31.8%;
        }
        @include if-device(laptop) {
          width: 31.8%;
        }
      }
    }
  }
  &__content {
    width: 40%;
    background-color: $white;
    height: 80%;
    border-radius: 10px;
    overflow-y: scroll;
    z-index: 99;
    position: relative;

    &::-webkit-scrollbar {
      width: 10px;
      border-radius: 10px 0 0 10px;
    }

    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      border-radius: 10px 0 0 10px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $first-color;
      border-radius: 10px 0 0 10px;
    }
    @include if-device(phone) {
      width: 90%;
    }
    @include if-device(tablet) {
      width: 90%;
    }
    @include if-device(laptop) {
      width: 70%;
    }
    @include if-device(desktop) {
      width: 60%;
    }
    &__top1 {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 15px 20px 15px 30px;
      border-bottom: 1px solid $first-color;
      background-color: $first-color;
      color: $white;
      border-radius: 0 10px 0 0;
      z-index: 100;
      position: sticky;
      top: 0;
      box-shadow: $shadow;
      @include if-device(phone) {
        padding: 15px 10px 15px 10px;
      }

      &__icon {
        font-size: 25px;
        cursor: pointer;
        @include if-device(phone) {
          font-size: 19px;
        }
      }
    }
  }
}

.openModal {
  animation: slideIn 0.6s forwards;
  transition: transform 0.3s ease-out;
}

@keyframes slideIn {
  0% {
    transform: translateY(-50px);
  }
  100% {
    transform: translateY(0);
  }
}

.closeModal {
  animation: slideOut 0.2s forwards;
  transition: transform 0.3s ease-out;
}

@keyframes slideOut {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-50px);
  }
}
