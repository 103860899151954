@import "../../../../public/styles/variables";

.serviceList {
  flex: 1;
  overflow-y: scroll;
  max-height: 100%;
  padding: 25px 10px;

  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-track {
    background-color: $white;
    border-radius: 3px;
  }

  &::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: $first-color;
  }
  &__subServices {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    row-gap: 30px;
    column-gap: 10px;
    &__list {
      list-style: none;
      &__title {
        margin-bottom: 10px;
        color: $second-color;
        // font-family: "NeuvaStd";
        font-size: 16px;
        text-transform: capitalize;
      }
      & > ul {
        list-style: none;
        & > li {
          padding: 6px 0;
          font-weight: 400;
          font-size: 14px;
          text-transform: capitalize;
          &:hover {
            color: $second-color;
          }
        }
      }
    }
  }
}
