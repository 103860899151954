@import "../../../public/styles/variables";
@import "../../../public/styles/breakpoints";

.customization {
  height: 550px;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  &__top {
    height: 50px;
    &__close {
      position: absolute;
      right: 10px;
      top: 10px;
      background-color: $third-color;
      height: 30px;
      width: 30px;
      display: grid;
      place-items: center;
      border-radius: 50%;
      cursor: pointer;
      font-weight: 600;
      transition: 0.3s ease;
      color: $white;
      &:hover {
        background-color: red;
      }
    }
  }

  &__loader {
    width: 100%;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__mid {
    flex: 1;
    overflow-y: auto;
    padding: 10px 40px 10px;

    @include if-device(phone) {
      padding: 10px;
    }

    &__content {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      row-gap: 20px;
    }
    &::-webkit-scrollbar {
      width: 1px;
    }

    &::-webkit-scrollbar-track {
      background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
      box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    }
  }

  &__bottom {
    height: 80px;
    left: 0;
    padding: 0 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    &__left,
    &__right {
      width: 50%;
    }

    &__left {
      & > h3 {
        font-family: $english-regular;
        letter-spacing: 2px;
        color: $first-color;
      }

      & > p {
        color: $typo-two;
        letter-spacing: 1px;
      }
    }

    &__right {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      &__btn {
        width: 60%;

        @include if-device(phone) {
          width: 90%;
        }
      }
    }
  }
}
