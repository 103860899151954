.socialButtons {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  column-gap: 20px;

  &__socialButton {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #fff;
    box-shadow: 0px 0px 4px #00000027;
    transition: 0.3s;
    text-decoration: none;

    &:hover {
      background-color: #f2f2f2;
      box-shadow: 0px 0px 6px 3px #00000027;
    }
  }
  &__instagram {
    background-color: #c13584;

    &__svg {
      color: #f2f2f2;
      font-size: 25px;
    }

    &:hover &__svg {
      color: #c13584;
    }
  }

  &__facebook {
    background-color: #3b5998;

    &__svg {
      color: #f2f2f2;
      font-size: 25px;
    }
    &:hover &__svg {
      fill: #3b5998;
    }
  }

  &__youtube {
    background-color: #d84747;

    &__svg {
      color: #f2f2f2;
      font-size: 25px;
    }
    &:hover &__svg {
      fill: #d84747;
    }
  }

  &__whatsapp {
    background-color: #128c7e;

    &__svg {
      color: #f2f2f2;
      font-size: 25px;
    }
    &:hover &__svg {
      fill: #128c7e;
    }
  }
}
