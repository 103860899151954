@import "../../../public/styles/variables";

.wrapper {
  width: 70px;
  height: 60px;
  position: relative;
  z-index: 1;

  &__dot {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    height: 100%;
    &__circle {
      width: 15px;
      height: 15px;
      position: relative;
      border-radius: 50%;
      background-color: $first-color;
      transform-origin: 50%;

      animation: circle7124 0.5s alternate infinite ease;

      &:nth-child(3) {
        left: auto;
        animation-delay: 0.3s;
      }

      &:nth-child(2) {
        animation-delay: 0.2s;
      }
    }
  }

  &__dotshadow {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    position: absolute;
    width: 100%;
    bottom: 50%;

    &__shadow {
      width: 15px;
      height: 3px;
      border-radius: 50%;
      background-color: rgba(0, 0, 0, 0.9);
      position: relative;
      top: 62px;
      transform-origin: 50%;
      z-index: -1;
      filter: blur(1px);
      animation: shadow046 0.5s alternate infinite ease;

      &:nth-child(5) {
        left: auto;
        animation-delay: 0.3s;
      }
      &:nth-child(4) {
        animation-delay: 0.2s;
      }
    }
  }
}

@keyframes circle7124 {
  0% {
    top: 60px;
    height: 5px;
    border-radius: 50px 50px 25px 25px;
    transform: scaleX(1.7);
  }

  40% {
    height: 20px;
    border-radius: 50%;
    transform: scaleX(1);
  }

  100% {
    top: 0%;
  }
}

@keyframes shadow046 {
  0% {
    transform: scaleX(1.5);
  }

  40% {
    transform: scaleX(1);
    opacity: 0.7;
  }

  100% {
    transform: scaleX(0.2);
    opacity: 0.4;
  }
}
