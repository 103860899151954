@import "../../../public/styles/variables";
.contactBtn {
  position: fixed;
  right: 20px;
  height: 60px;
  width: 60px;
  background-color: $first-color;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  box-shadow: $shadow;
  cursor: pointer;
  padding: 2px;
  z-index: 999;
  bottom: 70px;

  &__icon {
    font-size: 30px;
    color: $white;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  &:hover p {
    opacity: 1;
    transform: translateX(0);
  }

  &__pltr {
    position: absolute;
    left: -120px;
    background-color: $black;
    color: $white;
    padding: 5px 10px;
    border-radius: 5px;
    opacity: 0;
    transform: translateX(20px);
    transition: all 0.3s ease-in-out;
  }

  &__prtl {
    position: absolute;
    left: -80px;
    background-color: $black;
    color: $white;
    padding: 5px 10px;
    border-radius: 5px;
    opacity: 0;
    transform: translateX(20px);
    transition: all 0.3s ease-in-out;
  }
}

.languageBtn {
  position: fixed;
  right: 25px;
  height: 50px;
  width: 50px;
  background-color: $first-color;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  box-shadow: $shadow;
  cursor: pointer;
  padding: 2px;
  z-index: 999;
  bottom: 140px;

  &__pltr {
    color: $white;
    font-size: 20px;
  }

  &__prtl {
    color: $white;
    font-family: $english-regular;
    font-size: 30px;
    margin-top: -10px;
  }
}
