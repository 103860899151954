@import "../../../public/styles/variables";
@import "../../../public/styles/breakpoints";

.blogCard {
  width: 100%;
  box-shadow: $shadow;
  padding: 20px;

  &__img {
    width: 100%;
    height: 400px;

    @include if-device(first, 1024) {
      height: auto;
    }

    & > img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__date {
    margin: 10px 0;
    display: flex;
    align-items: center;
    column-gap: 20px;
    &__child {
      display: flex;
      align-items: center;
      column-gap: 5px;
      &__icon {
        color: gray;
        @include if-device(first, 1024) {
          font-size: 14px;
        }
      }

      & > p {
        font-family: $english-regular;
        font-size: 18px;
        @include if-device(first, 1024) {
          font-size: 14px;
        }
      }
    }
  }

  & > h2 {
    font-family: $english;
    font-size: 1.8rem;
    font-weight: 500;
    margin-bottom: 10px;
    @include if-device(first, 1024) {
      font-size: 1.2rem;
    }
  }

  &__btn {
    width: 150px;
    margin-top: 20px;
  }

  & > p {
    font-family: $english-regular;
    font-size: 14px;
    text-transform: capitalize;
    font-weight: 400 !important;
    color: $typo-one !important;
    line-height: 1.5;
    & > p {
      font-family: $english-regular !important;
      font-size: 14px !important;
      text-transform: capitalize;
      font-weight: 400 !important;
      color: $typo-one !important;
      line-height: 1.5;

      & > span {
        font-family: $english-regular !important;
        font-size: 14px !important;
        text-transform: capitalize;
        font-weight: 400 !important;
        color: $typo-one !important;
        line-height: 1.5;

        & > span {
          font-family: $english-regular !important;
          font-size: 14px !important;
          text-transform: capitalize;
          font-weight: 400 !important;
          color: $typo-one !important;
          line-height: 1.5;
          & > a {
            font-family: $english-regular !important;
            font-size: 14px !important;
            text-transform: capitalize;
            font-weight: 500 !important;
            color: blue;
          }
          & > span {
            font-family: $english-regular !important;
            font-size: 14px !important;
            text-transform: capitalize;
            font-weight: 400 !important;
            color: $typo-one !important;
            line-height: 1.5;
          }
        }
      }
    }
  }
}
