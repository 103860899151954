@import "../../../public/styles/variables";

.details {
  padding: 20px;

  &__loader {
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 50px;
  }

  & > h1 {
    text-align: center;
    font-family: $english-regular;
    font-size: 28px;
  }

  & > p {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 10px 0;

    &:nth-child(odd) {
      border-bottom: 0.5px solid $typo-one;
      margin-bottom: 20px;
      padding: 10px 0;
    }

    & > span {
      font-family: $english-regular;
    }
  }
}
