@import "../../../public/styles/variables";
@import "../../../public/styles/breakpoints";
.title {
  position: relative;
  & > h1 {
    height: 30px;
    display: inline-block;
    min-width: 100px;
    padding: 5px 10px;
    font-size: 16px;
    font-weight: 400;
    background-color: $first-color;
    color: #fff;
    border-radius: 20px;
    text-align: center;
  }
  & > h3 {
    font-size: 35px;
    font-weight: 700;
    line-height: 1.2;
    color: $second-color;
    position: relative;
    z-index: 10;
    @include if-device(phone) {
      font-size: 22px;
    }
    @include if-device(tablet) {
      font-size: 25px;
    }
  }
}
