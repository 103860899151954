@import "../../../../../public/styles/variables";
.reviewCard {
  display: flex;
  align-items: center;
  border: 1px solid $typo-one;
  width: 100%;
  padding: 10px;
  column-gap: 10px;
  background-color: $typo-two;
  border-radius: 30px;
  box-shadow: $shadow2;
  &__img {
    height: 60px;
    width: 60px;
    border-radius: 50%;
    overflow: hidden;

    & > img {
      height: 100%;
      width: 100%;
    }
  }

  &__content {
    & > p {
      color: $white;
      font-family: $english-regular;
      font-size: 16px;
    }
  }
}
