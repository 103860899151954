@import "../../../public/styles/variables";
@import "../../../public/styles/breakpoints";
.catCard {
  cursor: pointer;
  height: 100px;
  width: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  // width: 80px;
  // height: 80px;
  // position: relative;
  // text-align: center;
  // transform: rotate(20deg);
  // background-color: $first-color;
  // border-radius: 30% 70% 70% 30% / 30% 30% 70% 70%;
  transition: all 0.3s ease-in-out;
  position: relative;
  margin: 10px 0;

  // &:before {
  //   content: "";
  //   position: absolute;
  //   top: 0;
  //   left: 0;
  //   height: 80px;
  //   width: 80px;
  //   background: $first-color;
  //   transform: rotate(135deg);
  //   overflow: hidden;
  // }

  @include if-device(phone) {
    height: 30px;
    width: 30px;
  }

  &:hover img {
    transform: rotateY(180deg);
  }

  &__image {
    text-shadow: $shadow;
    height: 70px;
    width: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    // transform: rotate(-20deg);
    filter: drop-shadow(0 0.2rem 0.25rem rgba(0, 0, 0, 0.2));
    transition: all 0.3s cubic-bezier(0.19, 1, 0.22, 1);

    @include if-device(phone) {
      width: 60px;
      height: 60px;
    }

    & > img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      transition: all 0.4s ease-in-out;
    }
  }
  &__name {
    color: $third-color;
    // position: absolute;
    // bottom: -50px;
    // transform: translateX(-50%);
    min-width: 150px;
    text-transform: uppercase;
    transition: all 0.3s ease-in-out;
    font-size: 16px;
    left: 50%;
    display: inline-block;
    padding: 3px 5px;
    border-radius: 2px;
    transition: all 0.3s cubic-bezier(0.19, 1, 0.22, 1);
    transform-origin: left;
    text-align: center;
    // z-index: 2;

    @include if-device(phone) {
      width: 120px;
      font-size: 12px;
    }
  }
}

// @import "../../../../public/styles/variables";

// .chooseCard {
//   width: 200px;
//   height: 200px;
//   transition: all 0.2s;
//   position: relative;
//   cursor: pointer;

//   &:hover {
//     transform: scale(1.04) rotate(1deg);
//   }

//   &::before {
//     width: 100px;
//     height: 100px;
//     background: repeating-linear-gradient(48deg, #ffab02 0%, #fe6684 100%);
//     border-radius: 35% 30% 75% 30% / 49% 30% 70% 51%;
//     position: absolute;
//     animation: move-up6 2s ease-in infinite alternate-reverse;
//     z-index: 0;
//     top: 25px;
//     left: 25px;
//     content: "";
//   }

//   &::after {
//     width: 100px;
//     content: "";
//     height: 100px;
//     background: repeating-linear-gradient(48deg, #ffab02 0%, #fe6684 100%);
//     border-radius: 35% 30% 75% 30% / 49% 30% 70% 51%;
//     position: absolute;
//     animation: move-up6 2s ease-in infinite alternate-reverse;
//     z-index: 0;
//     bottom: 25px;
//     right: 25px;
//     animation-name: move-down1;
//   }

//   &__cardInner {
//     width: inherit;
//     height: inherit;
//     background: rgba(255, 255, 255, 0.05);
//     box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
//     backdrop-filter: blur(10px);
//     border-radius: 8px;
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     flex-direction: column;
//     padding: 10px;
//     z-index: 1;
//     position: relative;

//     &__img {
//       width: 80px;
//       height: 80px;
//       margin-bottom: 10px;

//       & > img {
//         width: 100%;
//         height: 100%;
//         object-fit: contain;
//       }
//     }

//     &__title {
//       font-size: 18px;
//       text-transform: capitalize;
//       color: $second-color;
//     }
//   }
// }

// @keyframes move-up6 {
//   to {
//     transform: translateY(-10px);
//   }
// }

// @keyframes move-down1 {
//   to {
//     transform: translateY(10px);
//   }
// }
