@import "../../../public/styles/breakpoints";

.Cropper {
  height: 400px;
  width: 400px;
  margin: 40px auto 0;
  @include if-device(first, 1024) {
    width: 100%;
    height: 400px;
    margin: 40px auto 0;
  }
}

.btn {
  display: flex;
  align-items: center;
  justify-content: center;
  row-gap: 20px;
  width: 50%;
  margin: 20px auto 40px;
  flex-direction: column;
}
