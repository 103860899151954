@import "../../../public/styles/breakpoints";
@import "../../../public/styles/variables";

.close {
  position: absolute;
  right: 10px;
  top: 10px;
  background-color: $third-color;
  height: 30px;
  width: 30px;
  display: grid;
  place-items: center;
  border-radius: 50%;
  cursor: pointer;
  font-weight: 600;
  transition: 0.3s ease;
  color: $white;
  &:hover {
    background-color: red;
  }
  @include if-device(third, 768) {
    right: 10px;
    top: 10px;
    z-index: 2;
  }
}
