@import "../../../public/styles/breakpoints";
@import "../../../public/styles/variables";
.mobileNav {
  margin: 10px 0 0 10px;
  border-radius: 10px;
  width: 280px;
  background-color: #fff;
  height: 680px;
  position: fixed;
  z-index: 998;
  top: 0;
  left: 0;
  box-shadow: $shadow;
  transition: all 0.3s ease-in-out;
  animation: in 1s forwards;
  display: none;
  overflow: hidden;

  @include if-device(phone) {
    display: block;
  }

  @include if-device(tablet) {
    display: block;
  }

  @include if-device(laptop) {
    display: block;
  }

  &__logout {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 20px;
    position: absolute;
    bottom: 5px;
    width: 100%;
  }

  &__menu2 {
    & > ul {
      display: flex;
      align-items: center;
      list-style-type: none;
      justify-content: space-between;
      background-color: $first-color;
      border-top: 1px solid $white;
      border-bottom: 1px solid $white;

      & > li {
        flex: 1;
        font-size: 12px;
        padding: 10px 5px;
        color: $white;
        font-family: $english-regular;
        cursor: pointer;
        text-transform: uppercase;
        display: flex;
        align-items: center;
        justify-content: center;

        // &:last-child {
        //   border-right: none;
        //   border-left: none;
        // }
      }
    }
  }

  &__topRight {
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 10px;
    background-color: #ffab02;
    height: 130px;
    flex-direction: column;

    & > p {
      color: #fff;
      margin-top: 8px;
    }
  }

  &__mainMenu {
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 10px;
    position: absolute;
    bottom: 65px;
    left: 50%;
    transform: translateX(-50%);

    & > div {
      height: 60px;
      width: 60px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      background-color: $third-color;
      border-radius: 10px;
      box-shadow: $shadow;
      padding: 10px;
      cursor: pointer;

      & > div {
        height: 30px;
        width: 30px;
        & > img {
          height: 100%;
          width: 100%;
          object-fit: contain;
          // filter: drop-shadow(0 0.2rem 0.25rem rgba(0, 0, 0, 0.2));
        }
      }

      & > p {
        font-size: 12px;
        color: #fff;
      }
    }
  }

  &__menu {
    max-height: 440px;
    overflow: auto;

    &::-webkit-scrollbar {
      width: 4px;
    }

    &::-webkit-scrollbar-track {
      background-color: #fff;
    }

    &::-webkit-scrollbar-thumb {
      background-color: gainsboro;
    }
  }

  &__right {
    &__profile {
      width: 80px;
      height: 80px;
      cursor: pointer;
      border-radius: 50%;
      overflow: hidden;
      & > img {
        width: 100%;
        height: 100%;
      }
    }
    &__login {
      display: flex;
      align-items: center;
      column-gap: 10px;
      border: 1px solid $border-color;
      height: 40px;
      width: 120px;
      justify-content: center;
      border-radius: 10px;
      cursor: pointer;
      padding: 0 10px;
      background-color: $first-color;
      @include if-device(phone) {
        height: 35px;
      }
      &__icon {
        color: $white;
        font-size: $icon-size;
      }
      & > p {
        font-size: $font-size;
        color: $white;
        font-family: $english;
        font-weight: 500;
        @include if-device(phone) {
          font-size: 18px;
        }
      }
    }
  }
  & > h2 {
    color: #ffab02;
    padding-left: 10px;
  }
}
