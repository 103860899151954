@import "../../../public/styles/breakpoints";
.parent {
  width: 100%;

  &__children {
    width: 1340px;
    margin: 0 auto;
    @include if-device(phone) {
      width: 100%;
      padding: 0 20px;
    }
    @include if-device(tablet) {
      width: 100%;
      padding: 0 20px;
    }
    @include if-device(laptop) {
      width: 100%;
      padding: 0 20px;
    }
    @include if-device(desktop) {
      width: 100%;
      padding: 0 20px;
    }
  }
}
