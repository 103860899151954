@import "../../../../public/styles/variables";
.title {
  font-size: 20px;
  font-weight: 500;
  color: $black;
  position: relative;
  margin-bottom: 20px;
  padding-bottom: 10px;
  border-bottom: 1px solid $typo-two;
}
