@font-face {
  font-family: "SF-Bold";
  font-style: normal;

  src: local("SF-Bold"), url("../../src/fonts/STC Forward Bold.ttf") format("truetype");
}
@font-face {
  font-family: "SF-Regular";
  font-style: normal;

  src: local("SF-Regular"), url("../../src/fonts/STC Forward Regular.ttf") format("truetype");
}
@font-face {
  font-family: "SF-Medium";
  font-style: normal;

  src: local("SF-Medium"), url("../../src/fonts/STC Forward Medium.ttf") format("truetype");
}

@font-face {
  font-family: "NeuvaStd";
  src: local("fonts"), url("../../src/fonts/NuevaStd-Bold.otf") format("truetype");
  font-weight: medium;
}

$white: #fff;
$black: #000;
$first-color: #ffab02;
$second-color: #15124b;
$third-color: #fe6684;

$typo-one: #707070;
$typo-two: #535b62;

$english-regular: "SF-Regular";
$english: "SF-Medium";
$arabic: "El Messiri";

$icon-size: 20px;
$font-size: 18px;

$font1: 16px;
$font2: 18px;
$font3: 20px;

$border-color: #eee;
$shadow: 0 1px 10px rgba(0, 0, 0, 0.24);
$shadow2: 0 -4px 10px rgba(50, 50, 50, 0.12);
$shadow3: 0 1px 8px rgba(0, 0, 0, 0.18);
