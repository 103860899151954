@import "../../../public/styles/variables";

.profile {
  padding: 40px;
  &__top {
    height: 10px;
    &__close {
      position: absolute;
      right: 10px;
      top: 10px;
      background-color: $third-color;
      height: 30px;
      width: 30px;
      display: grid;
      place-items: center;
      border-radius: 50%;
      cursor: pointer;
      font-weight: 600;
      transition: 0.3s ease;
      color: $white;
      &:hover {
        background-color: red;
      }
    }
  }

  &__profilepic {
    height: 150px;
    width: 150px;
    border-radius: 50%;
    border: 1px solid $typo-one;

    margin: 0 auto;
    position: relative;

    & > label {
      position: absolute;
      height: 30px;
      width: 30px;
      border-radius: 50%;
      background-color: $typo-one;
      color: $white;
      font-size: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      bottom: 0;
      right: 0;
      cursor: pointer;
    }

    & > img {
      height: 100%;
      width: 100%;
      border-radius: 50%;
      object-fit: contain;
    }

    &__input {
      height: 100%;
      width: 100%;
      display: none;
      & > input {
        height: 100%;
        width: 100%;
        appearance: none;
      }
    }
  }

  &__mid {
    margin-top: 20px;
  }
}

.loader {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 300px;
}
