@import "../../../public/styles/variables";
@import "../../../public/styles/breakpoints";

.loader {
  height: 300px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.h1 {
  margin-top: 50px;
  font-family: $english-regular;
  color: $black;
  @include if-device(first, 1024) {
    font-size: 24px;
  }
}

.wishlist {
  margin: 50px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  & > h1 {
    font-family: $english-regular;
    color: $typo-one;
    @include if-device(first, 1024) {
      font-size: 18px;
    }
  }

  & > img {
    width: 40%;
    height: 100%;

    @include if-device(third, 768) {
      width: 60%;
    }
  }
}

.wishlist1 {
  // border: 1px solid $typo-one;
  // padding: 20px;
  // box-shadow: $shadow;
  // border-radius: 5px;
  margin: 50px 0;

  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;

  @include if-device(phone) {
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  }
  @include if-device(tablet) {
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  }
  @include if-device(laptop) {
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  }
  @include if-device(desktop) {
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  }
}
