@import "../../../public/styles/breakpoints";

.testimonial {
  background-image: url("../../assets/testimonial/testimonial-bg.svg");
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 80px 0 60px;

  @include if-device(phone) {
    padding: 40px 0 30px;
  }

  &__top {
    text-align: center;
    @include if-device(phone) {
      padding: 0 20px;
    }
    &__title {
      font-family: "Pacifico", cursive;
      font-size: 27px;
      color: #fff;
      font-weight: 500;
    }

    &__desc {
      font-size: 36px;
      color: #fff;
      margin-bottom: 30px;
      font-weight: 500;
    }
  }

  &__bottom {
    width: 100%;
    margin-top: 50px;

    @include if-device(phone) {
      margin-top: 20px;
    }
  }
}
