@import "../../../public/styles/variables";
@import "../../../public/styles/breakpoints";

.wishlist {
  border: 1px solid $typo-one;
  padding: 20px;
  box-shadow: $shadow;
  border-radius: 5px;
  margin-bottom: 50px;

  & > h1 {
    font-family: $english-regular;
    border-bottom: 1px solid $typo-two;
    margin-bottom: 20px;
  }

  & > img {
    width: 100%;
  }

  &__loader {
    display: flex;
    gap: 20px;
    flex-direction: row;
  }

  &__body {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;

    @include if-device(phone) {
      grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    }

    @include if-device(tablet) {
      grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    }
  }
}
