@import "../../../public/styles/variables";
@import "../../../public/styles/breakpoints";

.btn {
  width: 100%;
  height: 45px;
  font-size: 18px;
  background-color: $third-color;
  border: 1px solid $third-color;
  border-radius: 10px;
  color: $white;
  cursor: pointer;
  font-size: 16px;
  font-family: $english-regular;

  @include if-device(first, 1024) {
    height: 40px;
  }
}
