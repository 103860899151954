.forStore {
  // width: 100%;
  &__store {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    column-gap: 20px;
    &__img {
      height: 50px;
      cursor: pointer;

      & > img {
        width: 100%;
        height: 100%;
      }
    }
  }
}
