@import "../../../../public/styles/variables";
@import "../../../../public/styles/breakpoints";

.title {
  margin-bottom: 40px;
  font-family: $english-regular;
  color: $typo-two;
  border-bottom: 1px solid $typo-two;

  @include if-device(phone) {
    font-size: 20px;
  }

  & > span {
    color: red;
    font-size: 28px;
  }
}
