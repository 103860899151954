.react-multi-carousel-item {
  display: flex;
  align-items: center;
  justify-content: center;
}

.react-multi-carousel-list {
  height: 100% !important;
}

.custom-dot-list-style {
  margin-bottom: 20px;
}

.custom-dot-list-style button {
  border: none;
  background: #fff;
  border: 1px solid #ffab02;
  height: 20px;
  width: 20px;
}

.react-multi-carousel-dot.react-multi-carousel-dot--active button {
  background: #ffab02 !important;
  width: 50px !important;
  border-radius: 10px;
}

.react-multiple-carousel__arrow {
  z-index: 10 !important;
}

@media screen and (max-width: 660px) {
  .custom-dot-list-style button {
    height: 10px;
    width: 10px;
  }

  .react-multi-carousel-dot.react-multi-carousel-dot--active button {
    width: 30px !important;
  }

  .swal2-title {
    font-size: 20px;
    font-weight: 400;
    text-transform: capitalize;
  }
}
