@import "../../../public/styles/variables";
@import "../../../public/styles/breakpoints";

.acordian {
  width: 100%;

  &__hedding {
    // border-bottom: 1px solid $first-color;

    &__contant {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 10px 5px;
      background-color: #fff;
      font-family: $english-regular;
      font-size: 20px;
      margin: 2px 0;
      flex-wrap: wrap;
      text-transform: capitalize;
      font-family: $english-regular;
      font-weight: 500;

      @include if-device(third, 768) {
        font-size: 16px;
      }

      &__icon {
        font-size: 20px;
        font-weight: 400;
        @include if-device(third, 768) {
          font-size: 16px;
        }
      }
    }

    &__SubContent {
      width: 100%;
    }
  }
  &__contantArea {
    & > ul {
      color: #fff;
      background-color: #15124b;
      margin: 5px 10px;
      box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
      list-style-type: none;
      border-radius: 10px;
      overflow: hidden;
      & > li {
        padding: 10px 8px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        // border-bottom: 1px solid #fff;
        margin: 1px 0;

        color: #fff;

        font-size: 18px;
        font-family: $english-regular;
        font-weight: 500;

        @include if-device(third, 768) {
          font-size: 14px;
        }

        & > i {
          cursor: pointer;
          &:hover {
            color: #7a0505;
          }
        }
      }
      & > ul {
        margin: 5px 10px;
        background-color: #ffab02;
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
        list-style-type: none;
        border-radius: 10px;
        overflow: hidden;
        & > li {
          padding: 10px;
          color: #fff;
          font-size: 16px;
          // margin: 2px 0 3px 1px;
          cursor: pointer;
          transition: 0.3s;
          font-weight: 400;

          font-family: $english-regular;
          font-weight: 500;

          @include if-device(third, 768) {
            font-size: 14px;
          }

          &:hover {
            background-color: #fff;
            color: #ffab02;
          }
        }
      }
    }
  }
}
.loder {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 300px;
}

.main {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 5px;
  background-color: #fff;
  font-family: $english-regular;
  font-size: 20px;
  margin: 2px 0;
  flex-wrap: wrap;
  text-transform: capitalize;
  cursor: pointer;
  font-family: $english-regular;
  font-weight: 600;

  @include if-device(third, 768) {
    font-size: 16px;
  }

  &__icon {
    font-size: 20px;
    font-weight: 400;
    @include if-device(third, 768) {
      font-size: 16px;
    }
  }
}
