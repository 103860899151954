$desktopMinWidth: 1024px !default;
$desktopMaxWidth: 1280px !default;
$tabletMinWidth: 768px !default;
$tabletMaxWidth: 1024px !default;
$largePhoneMinWidth: 480px !default;
$largePhoneMaxWidth: 768px !default;
$phoneMaxWidth: 480px !default;
// $phoneMinWidth: 320px !default;

@mixin if-device($device, $responsive: null) {
  @if $responsive == null {
    @if $device == desktop {
      @media only screen and (min-width: $desktopMinWidth) and (max-width: $desktopMaxWidth) {
        @content;
      }
    } @else if $device == laptop {
      @media only screen and (min-width: $tabletMinWidth) and (max-width: $tabletMaxWidth) {
        @content;
      }
    } @else if $device == tablet {
      @media only screen and (min-width: $largePhoneMinWidth) and (max-width: $largePhoneMaxWidth) {
        @content;
      }
    } @else if $device == phone {
      @media only screen and (max-width: $phoneMaxWidth) {
        @content;
      }
    }
  } @else {
    @if $device == first {
      @media only screen and (max-width: $desktopMaxWidth) {
        @content;
      }
    }
    @if $device == second {
      @media only screen and (max-width: $tabletMaxWidth) {
        @content;
      }
    }
    @if $device == third {
      @media only screen and (max-width: $largePhoneMaxWidth) {
        @content;
      }
    }
  }
}
