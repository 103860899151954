@import "../../../public/styles/variables";
@import "../../../public/styles/breakpoints";

.modalArea {
  display: flex;
  text-align: left;

  &__loader {
    height: 325px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__services {
    padding: 0 0 0 0;
    width: 27%;
    max-height: 100%;
    overflow-y: scroll;

    &::-webkit-scrollbar {
      width: 5px;
    }

    &::-webkit-scrollbar-track {
      background-color: $second-color;
      // border-radius: 3px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $first-color;
    }

    & > h2 {
      font-size: 18px;
      color: $second-color;
      position: relative;
      padding: 20px 20px 10px;
      border-bottom: 1px solid $first-color;
      position: sticky;
      top: 0;
      background-color: $white;
    }
    & > div {
      height: 325px;

      & > ul {
        list-style: none;
        & > li {
          display: flex;
          align-items: center;
          justify-content: space-between;
          text-transform: capitalize;
          padding: 12px 20px;
          font-size: $font-size;
          color: $second-color;
          border-bottom: 0.3px solid $first-color;
          font-size: 16px;
          &:hover {
            color: $first-color;
            box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
          }
        }
      }
    }
  }
}
// .active {
//   color: $first-color !important;
//   border-left: 5px solid $second-color;
// }
