@import "../../../public/styles/variables";
@import "../../../public/styles/breakpoints";

.packages {
  &__top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    // padding: 0 50px;
    text-align: left;
    margin-bottom: 20px;
    @include if-device(phone) {
      // padding: 0 15px;
      column-gap: 10px;
      align-items: flex-end;
    }
    &__heading {
      color: $third-color;
      font-size: 25px;
      background-color: $second-color;
      display: inline-block;
      padding: 0 10px 0 10px;
      color: $white;
      font-weight: 500;

      @include if-device(phone) {
        font-size: 16px;
        padding: 5px;
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex: 1;
      }

      &__title {
        display: inline-block;
        padding: 5px 10px 10px;
        color: $white;
        border-radius: 10px 0 0 10px;
        background-color: $first-color;
        @include if-device(phone) {
          border-radius: 10px 0 10px 0 !important;
          padding: 5px !important;
          margin-top: 5px;
        }
      }
    }
  }

  &__container {
    // display: flex;
    // flex-wrap: wrap;
    gap: 20px;

    &__slider {
      width: 100%;
    }
  }
}
