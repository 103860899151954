@import "../../../../public/styles/variables";
.exclusion {
  border: 1px solid $typo-one;
  padding: 20px;
  box-shadow: $shadow;
  border-radius: 5px;
  &__content {
    & > p {
      color: $typo-one;

      text-transform: capitalize;
      font-size: 15px !important;
      display: flex;
      font-family: $english-regular !important;
      column-gap: 10px;

      font-size: 16px !important;
      &::before {
        content: "\f00d";
        font-family: FontAwesome;
        color: #d11a2a;
        font-size: 14px;
        margin-top: 3px;
      }
    }
    & > ul {
      list-style-type: none;
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      row-gap: 10px;

      & > li {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        column-gap: 10px;
        font-family: $english-regular;
        font-size: 16px;
        color: $typo-two;
        & > span {
          font-size: 16px;
          font-family: $english-regular !important;
        }
        &::before {
          content: "\f00d";
          font-family: FontAwesome;
          color: #d11a2a;
          font-size: 14px;
          margin-top: 3px;
        }
      }
    }
  }
}
