@import "../../../public/styles/variables";
@import "../../../public/styles/breakpoints";

.customizeCard {
  width: 100%;
  box-shadow: $shadow;
  display: flex;
  border-radius: 10px;

  &__imgCont {
    width: 180px;
    height: 180px;
    border-radius: 10px;
    overflow: hidden;

    @include if-device(phone) {
      height: 150px;
      width: 150px;
    }

    & > img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  &__details {
    padding: 20px 20px 20px 10px;
    position: relative;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @include if-device(phone) {
      padding: 10px 10px 10px 5px;
    }

    &__left {
      & > h3 {
        font-family: $english-regular;
        font-size: 20px;
        color: $first-color;
        text-transform: capitalize;
        letter-spacing: 1px;
        margin-bottom: 10px;

        @include if-device(phone) {
          font-size: 16px;
        }
      }

      & > p {
        font-size: 18px;
        color: $typo-one;
        margin: 5px 0;

        @include if-device(phone) {
          font-size: 14px;
        }
      }
    }

    &__content {
      & > p {
        color: $typo-one;
      }
      // padding: 0 20px 0 10px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      &__counter {
        display: flex;
        align-items: center;
        &__buttons {
          height: 30px;
          width: 30px;
          border-radius: 20px;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
        }

        &__buttons1 {
          height: 30px;
          width: 30px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 24px;
          font-family: $english-regular;
          color: $typo-one;
        }
      }
    }
  }
}
