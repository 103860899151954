@import "../../../public/styles/variables";
@import "../../../public/styles/breakpoints";

.allProductList {
  padding: 50px 0;

  &__title {
    display: flex;
    align-items: center;
    font-family: $english-regular;
    font-size: 20px;
    margin-bottom: 30px;
    color: $typo-two;
    text-transform: capitalize;
    flex-wrap: wrap;

    @include if-device(phone) {
      font-size: 16px;
      margin-bottom: 10px;
    }

    &__angle {
      font-weight: 400;
      color: $third-color;
    }
  }
  &__body {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;
    @include if-device(phone) {
      grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    }
    @include if-device(tablet) {
      grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    }
    @include if-device(laptop) {
      grid-template-columns: repeat(3, 1fr);
    }
  }
}
