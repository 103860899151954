@import "../../../public/styles/variables";
.overlay {
  position: fixed;
  //   background-color: rgba(0, 0, 0, 0.4);
  height: 100%;
  width: 100%;
  right: 0;
  top: 0;
  z-index: 99;
}

.content {
  position: absolute;
  width: 240px;
  top: 71px;
  box-shadow: $shadow;
  background-color: $white;
  z-index: 100;

  &__triangle {
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;

    border-bottom: 10px solid $first-color;
    position: absolute;
    top: -9.5px;
    z-index: 99;
  }
  &__ul {
    list-style-type: none;
    &__li {
      display: flex;
      align-items: center;
      column-gap: 10px;

      cursor: pointer;

      &__icon {
        height: 50px;
        width: 50px;
        background-color: $first-color;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      &:hover {
        background-color: $first-color;
      }

      &:hover &__name {
        color: $white;
      }
    }
  }
}
