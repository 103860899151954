@import "../../../public/styles/variables";
@import "../../../public/styles/breakpoints";

.card {
  position: relative;
  min-width: 100%;
  background: $white;
  border-radius: 1rem;
  padding: 0.3rem;
  margin-bottom: 10px;
  box-shadow: $shadow;
  transition: all 0.5s ease-in-out;

  @include if-device(phone) {
    margin-top: 10px;
  }

  &__imageContainer {
    position: relative;
    width: 100%;
    border-radius: 0.7rem;
    border-top-right-radius: 4rem;
    margin-bottom: 1rem;
    // height: 200px;
    aspect-ratio: 1/1;

    &__svg {
      height: 100%;
      width: 100%;
      border-radius: inherit;

      & > img {
        width: 100%;
        height: 100%;
        -o-object-fit: inherit;
        object-fit: cover;
        border-radius: inherit;
      }
    }

    &__price {
      position: absolute;
      min-width: 80px;
      // right: 0.7rem;
      text-align: center;
      bottom: -1rem;
      background: $first-color;
      color: $white;
      font-weight: 500;
      font-size: 16px;
      padding: 0.5rem;
      border-radius: 1rem 1rem 2rem 2rem;
      box-shadow: rgba(100, 100, 111, 0.2) 0px 0px 15px 0px;
      @include if-device(phone) {
        font-size: 14px;
      }
    }
  }

  &__favorite {
    position: absolute;
    width: 19px;
    height: 19px;
    top: 5px;
    right: 5px;
    cursor: pointer;

    &__input {
      position: absolute;
      opacity: 1;
      width: 0;
      height: 0;

      &:checked &__svg {
        animation: bouncing 0.5s;
        fill: rgb(255, 95, 95);
        filter: drop-shadow(0px 3px 1px rgba(53, 53, 53, 0.14));
      }
    }

    &__svg {
      fill: rgb(255, 95, 95);
    }
  }

  &__content {
    padding: 0px 0.8rem;
    margin-bottom: 1rem;
    text-align: left;

    &__brand {
      font-size: 16px;
      font-weight: 700;
      color: $third-color;
      @include if-device(phone) {
        font-size: 14px;
      }
    }

    &__productName {
      font-weight: 500;
      color: $typo-two;
      font-size: 16px;
      margin-bottom: 1rem;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
      @include if-device(phone) {
        font-size: 12px;
      }
    }

    &__rating {
      color: $typo-one;
      font-size: 0.6rem;
      font-weight: 700;
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 0.5rem;

      &__star {
        display: flex;
        align-items: center;
        &__icon {
          font-size: 14px;
          @include if-device(phone) {
            font-size: 12px;
          }
        }
        & > span {
          font-size: 12px;
          font-family: $english-regular;
          margin-left: 5px;
        }
      }
      &__percent {
        font-size: 16px;
        color: $third-color;

        @include if-device(phone) {
          font-size: 12px;
        }
      }
    }
  }

  &__buttonContainer {
    display: flex;
    gap: 0.3rem;

    &__buyButton {
      flex: auto;
      border-radius: 30px;
      padding: 0.5rem 0;
      background-color: $second-color;
      color: white;
      font-weight: 500;
      cursor: pointer;
      border: 1px solid $second-color;
      transition: all 0.3s ease;

      &:hover {
        background: $white;
        border: 1px solid $second-color;
        color: $second-color;
      }
    }

    &__cartButton {
      display: grid;
      place-content: center;
      width: 50px;
      border-radius: 1.4rem 1.4rem 0.7rem 0.7rem;
      padding: 0.5rem 0;
      background-color: $first-color;
      color: white;
      font-weight: 500;
      cursor: pointer;
      border: 1px solid $first-color;
      transition: all 0.3s ease;

      &:hover {
        background: $white;
        border: 1px solid $first-color;
      }

      &:hover &__svg {
        fill: $first-color;
      }

      &__svg {
        width: 15px;
        fill: white;
      }
    }
  }

  &:hover {
    transform: scale(1.03);
  }
}

@keyframes bouncing {
  from,
  to {
    transform: scale(1, 1);
  }

  25% {
    transform: scale(1.5, 2.1);
  }

  50% {
    transform: scale(2.1, 1.5);
  }

  75% {
    transform: scale(1.5, 2.05);
  }
}
