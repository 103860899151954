@import "../../../../public/styles/variables";
.orderDetails {
  padding: 20px;
  background-color: white;
  position: relative;
  border: 1px solid $typo-one;
  box-shadow: $shadow;
  border-radius: 5px;
  &__input {
    display: flex;
    align-items: center;
    column-gap: 10px;
  }

  &__textarea {
    font-size: 1.1rem;
    padding: 1rem;
    font-family: inherit;
    color: #002247;
    border-radius: 12px;
    background-color: #f0f0f1;
    border: none;
    border-bottom: 3px solid transparent;
    width: 100%;
    min-height: 132px;
    display: block;
    transition: all 0.3s;
    font-family: $english-regular;

    &::placeholder {
      opacity: 1;
      color: #a3acb8;
      font-size: 1.1rem;
    }

    &:focus {
      outline: none;
    }
  }
}
