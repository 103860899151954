@import "../../../../public/styles/variables";
@import "../../../../public/styles/breakpoints";

.container {
  width: 30%;
  background: #f8f9fd;
  background: linear-gradient(0deg, rgb(255, 255, 255) 0%, rgb(244, 247, 251) 100%);
  border-radius: 40px;
  padding: 25px 35px;
  border: 5px solid rgb(255, 255, 255);
  box-shadow: rgba(254, 102, 132, 0.8784313725) 0px 30px 30px -20px;
  margin: 20px;
  position: relative;
  @include if-device(phone) {
    width: 95%;
    padding: 25px 15px;
  }
  @include if-device(tablet) {
    width: 75%;
  }
  @include if-device(laptop) {
    width: 60%;
  }
  @include if-device(desktop) {
    width: 50%;
  }
  &__close {
    position: absolute;
    right: 10px;
    top: 10px;
    background-color: $third-color;
    height: 30px;
    width: 30px;
    display: grid;
    place-items: center;
    border-radius: 50%;
    cursor: pointer;
    font-weight: 600;
    transition: 0.3s ease;
    color: $white;
    &:hover {
      background-color: red;
    }
  }

  &__logo {
    height: 120px;
    width: 120px;
    border: 10px solid $third-color;
    background-color: $white;
    border-radius: 50%;
    left: 50%;
    transform: translateX(-50%);
    padding: 20px;
    position: absolute;
    top: -50px;
    z-index: -1;
  }

  &__logo1 {
    height: 200px;
    width: 200px;
    // border: 10px solid $third-color;
    // background-color: $white;
    border-radius: 50%;
    left: 50%;
    transform: translateX(-50%);
    padding: 20px;
    position: absolute;
    top: -90px;
    z-index: 0;
  }

  &__imgCont {
    height: 90px;
    width: 90px;
    position: absolute;
    top: -30px;
    left: 50%;
    transform: translateX(-50%);
    & > img {
      height: 100%;
      width: 100%;
    }
  }

  &__heading {
    text-align: center;
    font-weight: 900;
    font-size: 30px;
    color: $third-color;
    // margin-top: 50px;
  }
  &__form {
    margin-top: 20px;
  }

  &__agreement {
    display: block;
    text-align: center;
    margin-top: 15px;

    & > p {
      text-decoration: none;
      font-size: 14px;
      color: $typo-one;
      text-transform: capitalize;

      & > span {
        color: $first-color;
        cursor: pointer;
      }
    }
  }

  &__socialAccountContainer {
    margin-top: 25px;

    &__title {
      display: block;
      text-align: center;
      font-size: 10px;
      color: $typo-one;
    }

    &__socialAccounts {
      width: 100%;
      display: flex;
      justify-content: center;
      gap: 15px;
      margin-top: 5px;

      &__socialButton {
        background: linear-gradient(45deg, rgb(0, 0, 0) 0%, rgb(112, 112, 112) 100%);
        border: 5px solid white;
        padding: 5px;
        border-radius: 50%;
        width: 40px;
        aspect-ratio: 1;
        display: grid;
        place-content: center;
        box-shadow: rgba(254, 102, 132, 0.8784313725) 0px 12px 10px -8px;
        transition: all 0.2s ease-in-out;
        cursor: pointer;

        & > img {
          height: 100%;
          width: 100%;
          object-fit: contain;
        }

        &:hover {
          transform: scale(1.2);
        }
        &:active {
          transform: scale(0.9);
        }
      }
    }
  }
}

// .form .forgot-password {
//   display: block;
//   margin-top: 10px;
//   margin-left: 10px;
// }

// .form .forgot-password a {
//   font-size: 11px;
//   color: #0099ff;
//   text-decoration: none;
// }
