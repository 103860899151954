@import "../../../../public/styles/variables";
@import "../../../../public/styles/breakpoints";

.list {
  cursor: pointer;
  display: flex;
  align-items: center;
  column-gap: 5px;
  text-decoration: none;

  &__icon {
    font-size: $icon-size;
    color: $first-color;
  }
  &__name {
    font-size: $font-size;
    color: $typo-two;
    transition: all 0.2s ease-in-out;

    @include if-device(desktop) {
      font-size: 16px;
    }

    &:hover {
      color: $first-color;
    }
  }
}
