@import "../../../public/styles/variables";

.searchBar {
  display: flex;
  border: 1px solid #eee;
  width: 250px;
  justify-content: space-between;
  align-items: center;
  height: 40px;
  border-radius: 5px;
  padding: 0 10px;
  position: relative;
  & > input {
    border: none;
    font-size: $font-size;
    font-family: $english;
    width: 100%;
    padding-right: 10px;

    &:focus {
      outline: none;
    }
  }

  &__icon {
    color: $first-color;
    font-size: $icon-size;
  }

  &__options {
    position: absolute;
    top: 40px;
    border: 2px solid $first-color;
    border-radius: 10px;
    box-shadow: $shadow;
    background-color: $white;
    overflow: hidden;
    width: 100%;
    left: 0;
    list-style-type: none;

    &__loader {
      height: 300px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    & > ul {
      overflow-y: auto;
      max-height: 300px;
      &::-webkit-scrollbar {
        width: 5px;
      }

      &::-webkit-scrollbar-track {
        background-color: transparent;
      }

      &::-webkit-scrollbar-thumb {
        // box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        border-radius: 20px;
        background-color: $first-color;
      }
      & > li {
        height: 40px;
        padding: 10px;
        text-transform: capitalize;
        font-size: 14px;
        cursor: pointer;

        &:hover {
          background-color: $first-color;
          color: $white;
        }
      }
    }
  }
}
