@import "../../../public/styles/variables";
@import "../../../public/styles/breakpoints";
.title {
  padding: 20px 0 10px;
  display: flex;
  align-items: center;
  font-family: $english-regular;
  font-size: 20px;
  color: $typo-two;
  text-transform: capitalize;

  & > p {
    display: flex;
    align-items: center;
    @include if-device(phone) {
      display: block;
    }
  }

  &__angle {
    font-weight: 400;
    color: $third-color;
  }
}

.banner {
  height: 400px;
  & > img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
  @include if-device(phone) {
    height: 200px;
    object-fit: inherit;
  }
}
.productDetails {
  padding: 20px 0 50px;
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  column-gap: 20px;

  &__left {
    position: sticky;
    top: 100px;
    width: 40%;
    @include if-device(phone) {
      position: static;
      margin-bottom: 20px;
      width: 100%;
    }
    @include if-device(tablet) {
      position: static;
      margin-bottom: 20px;
      width: 100%;
    }
  }

  &__right {
    // padding: 0 0 20px 20px;
    border-radius: 10px;
    width: 60%;
    display: flex;
    flex-direction: column;
    row-gap: 20px;

    @include if-device(phone) {
      width: 100%;
    }
    @include if-device(tablet) {
      width: 100%;
    }
  }
  @include if-device(phone) {
    display: block;
  }
  @include if-device(tablet) {
    display: block;
  }
}
.bottomDet {
  width: 100%;
  height: 60px;
  padding: 10px 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  bottom: 0;
  background-color: $white;
  z-index: 99;
  box-shadow: $shadow2;

  @include if-device(second, 1024) {
    justify-content: center;
    flex-wrap: wrap;
    padding: 10px 20px;
    row-gap: 10px;
    height: auto;
  }

  &__left {
    list-style-type: none;
    display: flex;
    align-items: center;
    column-gap: 20px;

    @include if-device(phone) {
      display: none;
    }

    & > li {
      cursor: pointer;
    }
  }

  &__right {
    display: flex;
    align-items: center;
    column-gap: 20px;

    &__full {
      font-family: $english-regular;
      color: $first-color;
      & > del,
      & > span {
        font-family: $english-regular;
        font-size: 16px;
        font-weight: 400;
        color: $typo-one;
      }
    }

    &__btn {
      width: 200px;

      @include if-device(laptop) {
        width: 150px;
      }
      @include if-device(phone) {
        width: 120px;
      }
    }

    & > p {
      display: flex;
      align-items: center;
      font-size: $font3;

      & > span {
        font-size: $font1;
      }
    }
  }
}
